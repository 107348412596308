import * as React from 'react'
import { Button, Col, ControlLabel, Form as FormUI, HelpBlock, Row } from 'react-bootstrap'
import { FieldArray } from 'react-final-form-arrays'
import { get } from 'lodash-es'
import moment from 'moment'

import { ButtonIcon } from 'syft-acp-atoms/Button'
import { addTimezone, formatISO8601 } from 'syft-acp-util/formatting'
import FormCondition from 'syft-acp-core/components/Form/FormCondition'
import FormTimeInput from 'syft-acp-core/components/Form/FormTimeInput'
import { listingShiftBreakTypes } from 'syft-acp-core/constants'

export const ListingShiftBreakDetailsFormUI = ({ shiftBooking, timeZone, attr, wrapper, form, errors }) => {
  const onAddBreak = React.useCallback(
    () => form.mutators.push(attr, { type: listingShiftBreakTypes.clocked }),
    [attr, form.mutators]
  )
  // agency shift clock in time || syft shift clock in time || shift start time
  const startTime = get(shiftBooking, 'shift.start_time')
  const endTime = get(shiftBooking, 'shift.end_time')
  const clockInTime = get(shiftBooking, 'clock_in_time') || get(shiftBooking, 'clock_in.time')
  const clockOutTime = get(shiftBooking, 'clock_out_time') || get(shiftBooking, 'clock_out.time')
  const startTimeRef = clockInTime || startTime
  const endTimeRef = clockOutTime || endTime

  const alignTimeToShift = React.useCallback(
    value => {
      const dateTime = moment(value)
      if (!dateTime.isValid()) return null

      const shiftStartTime = addTimezone(moment(startTimeRef), timeZone)
      const shiftEndTime = addTimezone(moment(endTimeRef), timeZone)
      const alignedDateTime = moment(shiftStartTime).hours(dateTime.hours()).minutes(dateTime.minutes())

      // if the input time is before shift start time, assume shift spans across 2 days and this break is for next days
      if (alignedDateTime.isBefore(shiftStartTime)) {
        const upliftedDateTime = moment(alignedDateTime).add(1, 'day')

        // if uplifted date is after shift end time, means incorrect input so return as is so further we can put some validation logic
        if (upliftedDateTime.isAfter(shiftEndTime)) {
          return formatISO8601(alignedDateTime)
        }

        return formatISO8601(upliftedDateTime)
      }

      return formatISO8601(alignedDateTime)
    },
    [endTimeRef, startTimeRef, timeZone]
  )
  const isValidBreakTime = React.useCallback(
    value => {
      if (!value) return 'Required'
      const valueTime = moment(value)
      const [shiftStartTime, shiftEndTime] = [startTimeRef, endTimeRef].map(time => addTimezone(moment(time), timeZone))

      if (!moment(valueTime).isBetween(shiftStartTime, shiftEndTime)) {
        const isClockInReference = clockInTime && moment(clockInTime).isValid()
        const isClockOutReference = clockOutTime && moment(clockOutTime).isValid()
        return `Break time must be between ${isClockInReference ? 'clock in' : 'shift start'} and ${
          isClockOutReference ? 'clock out' : 'shift end'
        } time`
      }
      return undefined
    },
    [clockInTime, clockOutTime, endTimeRef, startTimeRef, timeZone]
  )
  const formGroup = (
    <FormUI horizontal className="form-item">
      <FieldArray name={attr}>
        {({ fields }) =>
          fields.map((name, index) => {
            const fieldError = errors && get(errors, [attr, index, 'field'])
            return (
              // Display only clockable breaks
              (<FormCondition key={name} when={`${name}.type`} is={listingShiftBreakTypes.clocked}>
                <Row data-testid="listing-shift-break-row" className={`${fieldError ? 'has-error' : ''}`}>
                  <Col md={5}>
                    <Row className="row-no-gutters">
                      <Col md={5}>
                        <ControlLabel htmlFor={`clockIn-${index}`}>Start of break</ControlLabel>
                      </Col>
                      <Col md={7}>
                        <FormTimeInput
                          name={`${name}.clock_in_time`}
                          id={`clockIn-${index}`}
                          data-testid="listing-shift-break-start"
                          validate={isValidBreakTime}
                          timeZone={timeZone}
                          parse={alignTimeToShift}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col md={5}>
                    <Row className="row-no-gutters">
                      <Col md={5}>
                        <ControlLabel htmlFor={`clockOut-${index}`}>End of break</ControlLabel>
                      </Col>
                      <Col md={7}>
                        <FormTimeInput
                          name={`${name}.clock_out_time`}
                          id={`clockOut-${index}`}
                          data-testid="listing-shift-break-end"
                          validate={isValidBreakTime}
                          timeZone={timeZone}
                          parse={alignTimeToShift}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col md={1}>
                    <ButtonIcon
                      icon="trashcan"
                      id={`remove-${name}-${index}`}
                      data-testid="listing-shift-break-remove"
                      onClick={() =>
                        fields.update(index, { break_id: fields.value[index].break_id, type: 'delete_break' })
                      }
                    />
                  </Col>
                </Row>
                {fieldError && (
                  <Row className="has-error">
                    <Col sm={12}>
                      <HelpBlock>{fieldError}</HelpBlock>
                    </Col>
                  </Row>
                )}
              </FormCondition>)
            );
          })
        }
      </FieldArray>

      <Button onClick={onAddBreak} style={{ marginTop: 8 }}>
        Add break
      </Button>
    </FormUI>
  )

  if (wrapper) {
    return React.isValidElement(wrapper)
      ? React.cloneElement(wrapper, null, formGroup)
      : // if is not valid element, assume its a Component
        React.createElement(wrapper, null, formGroup)
  }

  return formGroup
}
