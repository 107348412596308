import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { isObject } from 'lodash-es'
import { validateShiftBooking } from '../ListingInputs/ListingShiftsBreaksDetails'
import { withTrackingTriggerHoc } from 'syft-acp-util/withTrackingTriggerHoc'
import { selectListingWorkerShifts, selectProvisionalBooking } from 'syft-acp-core/store/listings/selectors'
import { useFlexFlagIsOn } from '@indeed/flex-feature-flags'

import { Button } from 'syft-acp-atoms/Button'
import { saveBookings } from 'syft-acp-core/actions/bookings'
import { trackingEvents } from '../../tracking'
import { isRetroactive } from 'syft-acp-core/components/Modal/RetroactiveEditModal/RetroactiveEditModal'
import { showModal } from 'syft-acp-core/lib/modals'

const futureShiftIDs = (shifts, referenceTime) =>
  shifts.filter(shift => moment(shift.start_time).isSameOrAfter(referenceTime)).map(shift => shift.id)

const ListingSaveShiftsButton = ({
  dispatch,
  label,
  disabled,
  entityMapChanges,
  entityMap,
  job,
  provisional,
  shiftStartTime,
  triggerEvent,
}) => {
  const caMealBreakFlag = useFlexFlagIsOn('california_meal_breaks_acp')

  const onClick = () => {
    const { bookable_individually, shifts, id: jobID } = job

    if (!bookable_individually && provisional && shifts.length > 1) {
      const referenceTime = !!shiftStartTime ? moment(shiftStartTime) : moment()
      const jobShiftIDs = futureShiftIDs(shifts, referenceTime)

      // eslint-disable-next-line no-alert, no-restricted-globals
      const confirmed = confirm('This is a block booking, are you sure you want to save?')
      if (confirmed) {
        triggerEvent(trackingEvents.LISTING_INFO.BOOKINGS.SAVED, { jobID })
        dispatch(saveBookings({ entityMapChanges, entityMap, jobID, jobShiftIDs, caMealBreakFlag }))
      }
    } else {
      const changes = Object.values(entityMapChanges)

      const shift_id = shifts[0].id
      const shiftValues = Object.values(entityMap)
      const worker_id = shiftValues[0].worker.id

      changes.forEach(async booking => {
        const updates = Object.values(booking.update)
        if (updates) {
          let updated = updates[updates.length - 1] // last element ?
          let result = updated.map(update => Object.values(update))
          if (result.some(isRetroactive)) {
            await showModal('retroactiveEditModal', {}, null, { shift_id, worker_id })
          }
        }
      })
      triggerEvent(trackingEvents.LISTING_INFO.BOOKINGS.SAVED, { jobID })
      dispatch(saveBookings({ entityMapChanges, entityMap, jobID }))
    }
  }

  return (
    <Button kind="success" onClick={onClick} disabled={disabled}>
      {label}
    </Button>
  )
}

ListingSaveShiftsButton.propTypes = {
  dispatch: PropTypes.func.isRequired,
  triggerEvent: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  job: PropTypes.object.isRequired,
  entityMap: PropTypes.object.isRequired,
  entityMapChanges: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  provisional: PropTypes.bool.isRequired,
}

export default connect(state => {
  const { entityMapChanges = {}, isSavingData } = state.listingShiftBookings

  const getRecursiveValues = (obj, acc = []) => {
    if (isObject(obj)) {
      Object.keys(obj).forEach(key => getRecursiveValues(obj[key], acc))
    } else {
      acc.push(obj)
    }
    return acc
  }
  const hasChanges = getRecursiveValues(entityMapChanges).length

  const pickedEntityMap = selectListingWorkerShifts(state)
  const provisional = selectProvisionalBooking(state)
  const hasErrors = Object.values(pickedEntityMap).some(shiftBooking => !!validateShiftBooking(shiftBooking))
  const shiftStartTime = Object.values(pickedEntityMap).map(s => s.startTime)[0]
  const disabled = isSavingData || !hasChanges || hasErrors

  return {
    disabled,
    entityMapChanges,
    entityMap: pickedEntityMap,
    shiftStartTime,
    provisional,
  }
})(withTrackingTriggerHoc(ListingSaveShiftsButton))
