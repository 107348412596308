// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React, { useState, useCallback } from 'react'
import { FormGroup, InputGroup, FormControl } from 'react-bootstrap'
import { Button } from 'syft-acp-atoms/Button'

import EntityDetailItem from 'syft-acp-core/components/EntityDetail/EntityDetailItem'
import RecordSegment from 'syft-acp-core/components/RecordSection/RecordSegment'
import RecordControls from 'syft-acp-core/components/RecordSection/RecordControls'
import RecordHeader from 'syft-acp-core/components/RecordSection/RecordHeader'
import { EntitySubTabs } from 'syft-acp-core/components/EntityTabs'

import LoadingIndicator from 'syft-acp-util/components/LoadingIndicator'

import { alertsTab, filterAlerts, filterAlertsByResource } from './helpers'
import { tabTypes, defaulTitle } from './constants'
import { AlertsContainerProps } from './AlertsContainer.types'
import './AlertsContainer.css'

const AlertsContainer = ({
  runSearch,
  filter,
  allItems,
  employerID,
  isLoading,
  hasData,
  searchValue,
}: AlertsContainerProps) => {
  const [internalSearchValue, setInternalSearchValue] = useState<string>('')

  const search = useCallback(
    searchString => {
      runSearch(searchString != null ? searchString : internalSearchValue)
    },
    [runSearch, internalSearchValue]
  )

  const searchUpdate = useCallback(
    ev => {
      const searchString = ev.target.value
      setInternalSearchValue(searchString)
      search(searchString)
    },
    [search]
  )

  const title = filter?.employer
    ? `${defaulTitle} for employer ${filter.employer.brand_name || filter.employer.company_name}`
    : defaulTitle

  return (
    // @ts-expect-error
    (<EntityDetailItem className="AlertsContainer">
      <RecordControls className="overlayed">
        <LoadingIndicator loadingState={isLoading} />
        <FormGroup>
          <InputGroup>
            <FormControl type="text" onChange={searchUpdate} />
            <InputGroup.Button>
              <Button onClick={search} disabled={isLoading} className="search-button">
                Search
              </Button>
            </InputGroup.Button>
          </InputGroup>
        </FormGroup>
      </RecordControls>
      <RecordSegment noPadding noBorder>
        <RecordHeader main>{title}</RecordHeader>
      </RecordSegment>
      <RecordSegment withTable>
        {filter ? (
          /* Filtered view, listing only a subset of alerts relevant to e.g. a single employer. */
          (alertsTab({
            name: 'All',
            key: 'All',
            tabItems: filterAlerts(allItems, filter),
            searchValue: searchValue,
            isLoading,
            hasData,
          }))
        ) : (
          /* Default view, showing tabs. */
          (<EntitySubTabs noBorder id="alerts" defaultActiveKey="All">
            {alertsTab({
              name: 'All',
              key: 'All',
              tabItems: allItems,
              searchValue: searchValue,
              isLoading,
              hasData,
            })}
            {tabTypes.map(tab =>
              alertsTab({
                name: tab.name,
                key: tab.name,
                tabItems: filterAlertsByResource(allItems, tab.types),
                searchValue: searchValue,
                isLoading,
                hasData,
              })
            )}
          </EntitySubTabs>)
        )}
      </RecordSegment>
      <RecordSegment footer>Showing alerts {employerID && 'for this employer '}from the past 24 hours.</RecordSegment>
    </EntityDetailItem>)
  );
}

export default AlertsContainer
