// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'

import { useFlexFlagIsOn } from '@indeed/flex-feature-flags'
import PageView from 'syft-acp-core/components/PageView'
import AgenciesList from 'syft-acp-core/entities2/AgenciesList'
import AgenciesSubNavigation from './AgenciesSubNavigation'

export const Agencies = () => {
  const oneHostAgencyOnboarding = useFlexFlagIsOn('onehost_agency_onboarding')
  return (
    <>
      {oneHostAgencyOnboarding && <AgenciesSubNavigation />}
      <PageView title="Agencies list" entityPage>
        <AgenciesList />
      </PageView>
    </>
  )
}
