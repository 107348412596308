import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { get } from 'lodash-es'

import * as filtersActions from 'syft-acp-core/store/filters/actions'
import { fetchAgencies, clearAgencies } from 'syft-acp-core/actions/agencies'
import { Button } from 'syft-acp-atoms/Button'
import usePrevValue from 'syft-acp-util/hooks/usePrevValue'

import {
  updateAgenciesRanks,
  removeFromAgenciesByRankValue,
  makeAgenciesOptions,
  filterByFlexible,
} from './dataAgenciesDropdownHelpers'
import DataAgenciesDropdownItem from './DataAgenciesDropdownItem'

import type { DataAgenciesDropdownResult, MakeAgenciesMap } from './DataAgenciesDropdown.types'

import './DataAgenciesDropdown.css'

interface DataAgenciesDropdownImplementationProps {
  type: 'primary' | 'other'
  value?: DataAgenciesDropdownResult[] | null
  disabled?: boolean
  editable: boolean
  onChange: (
    newList: Pick<DataAgenciesDropdownResult, 'agency_id' | 'flexible' | 'rank' | 'work_location_id'>[],
  ) => void
  header: string
  agencies: MakeAgenciesMap
  data?: { id: number; platformID: number | null } | null
  actions: {
    fetchAgencies: (platformID: number | null) => void
    clearAgencies: () => void
  }
}

function getOptions(platformID: number | null, agencies: MakeAgenciesMap): { id: number; label: string }[] {
  if (platformID !== 1) {
    return makeAgenciesOptions({ agencies })
  }

  return []
}

function DataAgenciesDropdown({
  type,
  value: _value = [],
  disabled = false,
  editable,
  onChange,
  header,
  agencies,
  data,
  actions,
}: DataAgenciesDropdownImplementationProps) {
  const value = useMemo(() => _value ?? [], [_value])
  const platformID = useMemo(() => data?.platformID ?? null, [data])
  const prevPlatformID = usePrevValue(platformID)
  const [options, setOptions] = useState(() => getOptions(platformID, agencies))

  useEffect(() => {
    setOptions(getOptions(platformID, agencies))
  }, [platformID, agencies])

  useEffect(() => {
    if (prevPlatformID !== platformID) {
      actions.fetchAgencies(platformID)
    }
  }, [actions, platformID, prevPlatformID])

  useEffect(() => {
    return () => actions.clearAgencies()
    // eslint-disable-next-line react-hooks/exhaustive-deps -- this should be called only on unmount
  }, [])

  const handleChangeDropdown = useCallback(
    (agencyId: number, rank: number, id: number) => {
      if (!data?.id) return

      const updatedResults = updateAgenciesRanks({
        data: { id: data.id },
        results: value ?? [],
        type,
        value: agencyId,
        rank,
        id,
      })

      onChange(updatedResults)
    },
    [onChange, data, value, type],
  )

  const handleAddNewAgency = useCallback(() => {
    if (!data?.id) return

    const sortResultsByRank = [...value].sort(
      (first, second) => get(first, 'rank', 0) - get(second, 'rank', 0),
    )

    // Split sorted results into primary and other agency lists
    const primaryAgencyResults = sortResultsByRank.filter(filterByFlexible('primary'))
    const otherAgencyResults = sortResultsByRank.filter(filterByFlexible('other'))

    /**
     * When adding to 'primary' list make sure to re-rank other agencies
     */
    if (type === 'primary') {
      const newRank = get(primaryAgencyResults, `[${primaryAgencyResults.length - 1}].rank`, 0) + 1
      const newPrimaryAgenciesData = [
        ...primaryAgencyResults,
        {
          agency_id: null,
          rank: newRank,
          work_location_id: data.id,
          flexible: false,
        },
      ]
      const newOtherAgenciesData = otherAgencyResults.map((agency, index) => ({
        ...agency,
        flexible: true,
        rank: 1 + index + newRank,
      }))
      const allData = [...newPrimaryAgenciesData, ...newOtherAgenciesData]
      /**
       * Make sure to propogate change to the upper state
       * to update other agencies dropdowns
       */
      onChange(allData)
    } else {
      const flexibleResults = [
        ...primaryAgencyResults.map(agency => ({ ...agency, flexible: false })),
        ...otherAgencyResults.map(agency => ({ ...agency, flexible: true })),
      ]
      const rank = get(flexibleResults, `[${flexibleResults.length - 1}].rank`, 0) + 1
      const allData = [
        ...flexibleResults,
        {
          agency_id: null,
          rank,
          work_location_id: data.id,
          flexible: true,
        },
      ]
      /**
       * Make sure to propogate change to the upper state
       * to update other agencies dropdowns
       */
      onChange(allData)
    }
  }, [data, value, type, onChange])

  const handleDeleteClick = useCallback(
    (rank: number) => {
      const updatedResults = removeFromAgenciesByRankValue({
        results: value,
        rank,
      })

      onChange(updatedResults)
    },
    [value, onChange],
  )

  const deletable = editable && !disabled

  return (
    <div className="DataAgenciesDropdown">
      {value.filter(filterByFlexible(type)).map(agency => (
        <DataAgenciesDropdownItem
          agency={agency}
          deletable={deletable}
          disabled={disabled}
          editable={editable}
          header={header}
          key={agency.rank}
          onChange={handleChangeDropdown}
          onDeleteClick={handleDeleteClick}
          options={options}
          values={value}
        />
      ))}
      <Button kind="success" onClick={handleAddNewAgency}>
        New {type === 'primary' ? 'primary' : ''} agency
      </Button>
    </div>
  )
}

export default connect(
  // @ts-expect-error -- missing types
  (state, { onChange }) => ({
    actions: {
      setFilter: (_: unknown, results: DataAgenciesDropdownResult[]) => onChange(results),
    },
    agencies: state.agencies.entityMap,
    // @ts-expect-error -- missing types
    hasAgenciesData: state.agencies.hasData,
  }),
  // @ts-expect-error -- missing types
  (dispatch, { actions }) => {
    const boundActions = bindActionCreators(
      {
        ...filtersActions,
        fetchAgencies,
        clearAgencies,
      },
      dispatch,
    )
    return {
      actions: {
        ...boundActions,
        ...actions,
      },
    }
  },
)(DataAgenciesDropdown)
