import React from 'react'

import { updateBooking, updateBookingChanges } from 'syft-acp-core/actions/bookings'
import { DataDatetime } from 'syft-acp-core/components/EditableTable'

import { useDispatch } from 'react-redux'

type ListingShiftDateTimePickerProps = {
  value: string
  shiftBookingId: number
  attr: string
  disabled?: boolean
  timeZone?: string
}

export const ListingShiftDateTimePicker = ({
  value,
  disabled,
  timeZone,
  shiftBookingId,
  attr,
}: ListingShiftDateTimePickerProps) => {
  const dispatch = useDispatch()

  const handleChange = (val: string) => {
    dispatch(updateBooking({ id: shiftBookingId, attr, val }))
    dispatch(updateBookingChanges({ id: shiftBookingId, attr, val }))
  }

  return (
    <DataDatetime
      value={value}
      onChange={handleChange}
      disabled={disabled}
      parameters={{ timeZone }}
      showTimezone={false}
      editable
      data={{}}
    />
  )
}
