// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import { capitalize, cloneDeep, pickBy, pull, sortBy, times } from 'lodash-es'
import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { Button } from 'syft-acp-atoms/Button'
import { fetchEmployerFilteredVenuesList } from 'syft-acp-core/actions/employer-venues-filtered'
import EmployerAccountsNewLocationModal from 'syft-acp-core/components/Modal/EmployerAccountsNewLocationModal'
import { store } from 'syft-acp-core/store'
import { showModal } from 'syft-acp-core/store/modals/actions'
import { FilterBoolCheckboxButton } from 'syft-acp-util/components/FilterForm/FilterBoolCheckbox'
import Table from 'syft-acp-util/components/Table'

import { entityList$ } from 'syft-acp-core/reducers/generators/entities'
import './DataPermissions.css'

// Note: manage_ added to the start of management permissions.
const GLOBAL_MANAGEMENT_PERMISSIONS = ['employer_network', 'financial', 'venues']
const GLOBAL_OTHER_PERMISSIONS = ['create_venues', 'approve_bookings']
const LOCAL_MANAGEMENT_PERMISSIONS = ['location_network', 'timesheet', 'jobs']
const LOCAL_OTHER_PERMISSIONS = ['scan_workers', 'view_financial', 'location_approve_bookings', 'edit_venue']

const sanitizeName = name => capitalize(name.replace(/_/g, ' '))

const PermissionButton = ({ name, state, callback }) => (
  <FilterBoolCheckboxButton
    className="FilterBoolCheckboxButton"
    handleChange={callback}
    label={name}
    checked={state}
  />
)

PermissionButton.propTypes = {
  callback: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  state: PropTypes.bool.isRequired,
}

class DataPermissions extends PureComponent {
  static propTypes = {
    actions: PropTypes.shape({
      fetchEmployerFilteredVenuesList: PropTypes.func.isRequired,
    }).isRequired,
    context: PropTypes.object,
    employerID: PropTypes.string,
    venues: PropTypes.arrayOf(PropTypes.object).isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.arrayOf(PropTypes.object),
  }

  static defaultProps = {
    value: [],
    context: {},
    employerID: null,
  }

  componentDidMount() {
    this.props.actions.fetchEmployerFilteredVenuesList(this.props.context.employerID)
  }

  toggleButton = (label, index) => () => {
    const newValue = cloneDeep(this.props.value)
    // Remove the value if it's there, or add it if it isn't.
    if (newValue[index].codes.indexOf(label) > -1) {
      newValue[index].codes = pull(newValue[index].codes, label)
    } else {
      newValue[index].codes = [...newValue[index].codes, label]
    }

    // Send our modified value back.
    this.props.onChange(newValue)
  }

  insertNewLocation = id => {
    this.props.onChange(
      sortBy([{ work_location_id: id, codes: [] }, ...cloneDeep(this.props.value)], ['work_location_id']),
    )
  }

  generatePermissions = (v, vIndex, n, isGlobal, maxManagement, maxOther, venues) => {
    const managementPermissions = v.codes.filter(m => m.startsWith('manage_'))
    const otherPermissions = v.codes.filter(m => !m.startsWith('manage_'))
    const venueID = v.work_location_id
    const venueInfo = venues.find(venue => venue.id === venueID) || {}
    const venueLink = venueInfo.parent
      ? `/entity/employers/view/${this.props.context.employerID}/venues/view/${venueInfo.parent}/areas/view/${venueID}`
      : `/entity/employers/view/${this.props.context.employerID}/venues/view/${venueID}`

    return (
      <tr key={`${venueID}`}>
        {venueID ? (
          [
            <td>
              <div className="venue-id" key={`${venueID}.${n}.id`}>
                <span className="text">{venueID}</span>
              </div>
              <div className="venue-link" key={`${venueID}.${n}.link`}>
                <Link to={venueLink} className="link">
                  {venueInfo.name}
                </Link>
              </div>
            </td>,
          ]
        ) : (
          <td>
            <span className="text">Company-wide</span>
          </td>
        )}
        <td key={`${venueID}.${n}.manage`}>
          <div className="permissions-container">
            {times(maxManagement, m => {
              const name = isGlobal ? GLOBAL_MANAGEMENT_PERMISSIONS[m] : LOCAL_MANAGEMENT_PERMISSIONS[m]
              const state = managementPermissions.indexOf(`manage_${name}`) > -1
              return name ? (
                <PermissionButton
                  name={sanitizeName(name)}
                  state={state}
                  callback={this.toggleButton(`manage_${name}`, vIndex)}
                />
              ) : null
            })}
          </div>
        </td>
        <td key={`${venueID}.${n}.other`}>
          <div className="permissions-container">
            {times(maxOther, m => {
              const name = isGlobal ? GLOBAL_OTHER_PERMISSIONS[m] : LOCAL_OTHER_PERMISSIONS[m]
              const state = otherPermissions.indexOf(name) > -1
              return name ? (
                <PermissionButton
                  name={sanitizeName(name)}
                  state={state}
                  callback={this.toggleButton(name, vIndex)}
                />
              ) : null
            })}
          </div>
        </td>
      </tr>
    )
  }

  addNewLocation = () => {
    store.dispatch(showModal('employerAccountsNewLocationModal'))
  }

  render() {
    const {
      value,
      venues,
      context: { employerID },
    } = this.props
    if (!employerID) {
      return null
    }

    const maxManagement = Math.max(GLOBAL_MANAGEMENT_PERMISSIONS.length, LOCAL_MANAGEMENT_PERMISSIONS.length)
    const maxOther = Math.max(GLOBAL_OTHER_PERMISSIONS.length, LOCAL_OTHER_PERMISSIONS.length)

    // We need to convert our array to an object to maintain key integrity.
    const objValue = { ...value }
    const valuesLocal = pickBy(objValue, v => v.work_location_id !== null)
    const valuesGlobal = pickBy(objValue, v => v.work_location_id === null)

    return (
      <div className="DataPermissions">
        <Table>
          <tbody>
            <tr>
              <th>Location</th>
              <th>Management permissions</th>
              <th>Other permissions</th>
            </tr>
            {Object.keys(valuesLocal).map((v, n) =>
              this.generatePermissions(valuesLocal[v], v, n, false, maxManagement, maxOther, venues),
            )}
            {Object.keys(valuesGlobal).map((v, n) =>
              this.generatePermissions(valuesGlobal[v], v, n, true, maxManagement, maxOther, venues),
            )}
          </tbody>
        </Table>
        <EmployerAccountsNewLocationModal
          employerLocations={venues}
          newLocationCallback={this.insertNewLocation}
        />
        <Button onClick={this.addNewLocation}>Add new location</Button>
      </div>
    )
  }
}

export default connect(
  (state, { context: { employerID } }) => ({
    venues: entityList$(state.employerVenuesFiltered, { employerID }),
  }),
  dispatch => ({
    actions: bindActionCreators({ fetchEmployerFilteredVenuesList }, dispatch),
  }),
)(DataPermissions)
