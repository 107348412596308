// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'
import { get } from 'lodash-es'
import { Grid, Row, Col } from 'react-bootstrap'
import octicons from 'octicons'

import { apiURL } from 'syft-acp-core/api/endpoints'
import { notify } from 'syft-acp-core/actions/notifications'
import { store } from 'syft-acp-core/store'
import { stringify } from 'syft-acp-core/store/filters/helpers'
import * as tableType from 'syft-acp-core/components/EntityList/'
import CopiedText from 'syft-acp-core/components/CopiedText'
import entityDetailList from 'syft-acp-core/components/EntityDetail/entityDetailList'
import entityList from 'syft-acp-util/entityList'
import SiteComponent from 'syft-acp-core/components/SiteComponent'

import ErrorControls from './ErrorControls'
import StoreSize from './StoreSize'

import './ErrorLog.css'

// Reduces the size of a request ID for display.
const formatReqID = id => {
  const idStr = id || ''
  return `${idStr.split('-')[0]}…`
}

// Reduces the size of a query for display.
const formatQuery = query => {
  const queryStr = query || ''
  return queryStr.length > 12 ? `${queryStr.slice(0, 12)}…` : queryStr
}

// Makes an easily readable string to post on Slack from an error object.
const makeDebugString = data => {
  return `*Error report for endpoint \`${getAPIBaseURL(get(data, 'url', '(unknown endpoint)'))}\`* (req.ID: \`${get(
    data,
    'requestID',
    '(no request ID)'
  )}\`):

\`\`\`
${JSON.stringify(data, null, 2)}
\`\`\`
`
}

// Displays a notification stating that the error's data was copied to the clipboard.
const notifyCopy = () =>
  store.dispatch(
    notify('success', {
      title: `Error data copied`,
      message: 'Paste the message on Slack for debugging',
    })
  )

// Get the current API base URL, without query string prefix.
const getAPIBaseURL = url => {
  const base = apiURL('').replace(/\?$/, '')
  return url.replace(base, '').replace(/\?$/, '');
}

// Returns a copy button for this error row.
const getCopyButton = data => (
  <CopiedText
    onClick={ev => {
      ev.preventDefault()
      notifyCopy()
    }}
    copyValue={makeDebugString(data)}
    noHide
    noFloat
  />
)

const tableFormat = [
  {
    type: tableType.TXT,
    val: d =>
      get(d, 'message', '')
        .replace(/^Error/, '')
        .trim(),
    header: 'Error',
    classes: ['minimal'],
  },
  {
    type: tableType.BOOL,
    val: '_cleanError',
    headerIcon: octicons.check,
    headerIconAlt: 'Checkmark',
    classes: ['minimal'],
    expl: 'Has body (was able to parse response JSON)',
  },
  { type: tableType.TXT, val: d => getAPIBaseURL(get(d, 'url', '')), header: 'API endpoint' },
  {
    type: tableType.TXT,
    val: 'page.path',
    header: 'Page',
    expl: 'Active page at the moment the error occurred',
    classes: ['minimal'],
  },
  {
    type: tableType.TXT,
    val: d => stringify(get(d, 'page.query', {})),
    copyable: true,
    header: 'Query',
    expl: 'Active query values on the page',
    format: formatQuery,
    classes: ['shortened'],
  },
  {
    type: tableType.MONO,
    val: 'requestID',
    copyable: true,
    header: 'Req. ID',
    expl: 'ID for this request (used for debugging)',
    format: formatReqID,
    classes: ['shortened'],
  },
  {
    type: tableType.MONO,
    val: 'runtime',
    header: 'Runtime',
    expl: 'How long it took the API to process the request',
    classes: ['minimal'],
  },
  { type: tableType.DATETIME, val: 'timestamp', header: 'Time', classes: ['minimal'] },
  {
    type: tableType.CUSTOM_COMPONENT,
    val: d => getCopyButton(d),
    header: 'Copy',
    expl: 'Copy information for the development team',
    classes: ['button-wrapper', 'minimal-header'],
  },
]

// Fixme. Testing values
const YELLOW_RESPONSE = 2
const RED_RESPONSE = 5

const filters = null
const actionFormat = []

const ShiftListWrapper = entityDetailList({ title: 'Error log', withPagination: true })

const ErrorList = entityList(
  ShiftListWrapper,
  tableFormat,
  filters,
  actionFormat,
  'errors',
  {},
  {
    localPagination: true,
    hasLinks: false,
    noSets: true,
    recordList: true,
    showResultCount: false,
    rowClasses: data => {
      if (data.runtime > RED_RESPONSE) return 'red'
      if (data.runtime > YELLOW_RESPONSE) return 'yellow'
      return ''
    },
  }
)

export default class ErrorLog extends React.PureComponent {
  render() {
    return (
      <SiteComponent className="ErrorLog">
        <Grid>
          <Row>
            <Col md={12} sm={12}>
              <ErrorControls />
              <ErrorList />
              <StoreSize />
            </Col>
          </Row>
        </Grid>
      </SiteComponent>
    )
  }
}
