import { enrichBulkEditParams } from './bulkEditParams'
import { enrichCancelBookingParams } from './cancelBookingParams'
import { enrichCreateBookingParams } from './createBookingParams'
import { enrichRemoveBookingParams } from './removeBookingParams'
import { enrichReplaceBookingParams } from './replaceBookingParams'

export const buildSaveBookingParams = (entityMap, entityMapChanges, jobShiftIDs, caMealBreakFlag) => {
  const changedIds = Object.keys(entityMapChanges)

  const bulkEditParams = []
  const createBookingParams = []
  const replaceBookingParams = []
  const cancelBookingParams = []
  const removeBookingParams = []

  changedIds.forEach(id => {
    const changedEntity = entityMap[id]
    const actions = entityMapChanges[id]

    Object.keys(actions).forEach(action => {
      const attrs = actions[action]

      if (attrs === undefined) {
        return
      }

      switch (action) {
        case 'cancel':
          enrichCancelBookingParams(cancelBookingParams, changedEntity)
          break
        case 'remove':
          enrichRemoveBookingParams(removeBookingParams, changedEntity)
          break
        case 'create':
          enrichCreateBookingParams(createBookingParams, attrs, changedEntity, jobShiftIDs)
          break
        case 'replace':
          Object.keys(attrs).forEach(attr => {
            const value = attrs[attr]
            enrichReplaceBookingParams(replaceBookingParams, attr, value, changedEntity)
          })
          break
        case 'update':
          Object.keys(attrs).forEach(attr => {
            const value = attrs[attr]
            enrichBulkEditParams(bulkEditParams, attr, value, changedEntity, caMealBreakFlag)
          })
          break
        default:
          break
      }
    })
  })

  return {
    bulkEditParams,
    createBookingParams,
    replaceBookingParams,
    cancelBookingParams,
    removeBookingParams,
  }
}
