// Modified from https://github.com/Codebrahma/react-multi-level-selector
import React from 'react'
import PropTypes from 'prop-types'
import { get, isEqual } from 'lodash-es'
import listensToClickOutside from 'react-onclickoutside'
import suffixedClassName from './suffixedClassName'
import findParentStructure, { filterItems } from './helpers'
import './FilterHierarchyMultiselect.css'

const clasnameOptionsGroup = 'options-group'
class FilterHierarchyMultiselect extends React.PureComponent {
  constructor(props) {
    super()
    this.state = {
      values: props.values,
      filteredOptions: props.options,
      isMenuOpen: false,
      searchValue: '',
    }
    this.searchBox = React.createRef()
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.options, this.props.options)) {
      this.setState({ filteredOptions: this.props.options })
      if (prevProps.refreshValues) {
        this.setState({ values: [] })
        this.props.onChange([])
      }
    }
    if (!isEqual(prevProps.values, this.props.values) && !isEqual(this.state.values, this.props.values)) {
      this.setState({ values: this.props.values })
    }
  }

  getClassName = suffix => {
    const { className } = this.props

    return suffixedClassName(className, suffix)
  }

  onOptionsChange = () => {
    const { onChange } = this.props
    const { values } = this.state
    this.setState({
      searchValue: '',
    })
    onChange(values)
  }

  removeSelectedGroup = ({ value }, removeAll) => {
    const { values } = this.state
    this.setState(
      { values: removeAll ? [] : values.filter(data => data.value !== value) },
      this.onOptionsChange,
    )
  }

  handleClickOutside = () => {
    const { isMenuOpen } = this.state

    return isMenuOpen && this.setState({ isMenuOpen: false })
  }

  toggleMenu = event => {
    const { withSearch } = this.props
    const { isMenuOpen } = this.state

    event.stopPropagation()
    event.preventDefault()

    if (withSearch && !isMenuOpen) {
      this.searchBox.current.focus()
    }
    this.setState({ isMenuOpen: !isMenuOpen })
  }

  selectOption = (data, parent, event) => {
    const { values } = this.state
    const { value, checked, name } = event.target
    if (checked) {
      const parentValue = data.value
      const updatedOption = data
      const isOptionAvailable = values.findIndex(option => option.value === parentValue)

      if (isOptionAvailable === -1) {
        return this.setState({ values: [...values, updatedOption] }, this.onOptionsChange)
      }

      const updatedOptionsData = values.map(item => {
        if (item.value === parentValue) return updatedOption
        return item
      })
      return this.setState({ values: updatedOptionsData }, this.onOptionsChange)
    }

    const uncheckedOption = this.removeOption(values, parent, value, parent, name)
    return this.setState({ values: uncheckedOption }, this.onOptionsChange)
  }

  // remove options
  removeOption = (values, optionParent, removeOption, removeOptionParent, name) =>
    values.filter(item => {
      if (String(get(item, 'value')) === String(removeOption) && get(item, 'label') === name) {
        // checks if parent are undefined bcz level 1 menu dont have parents
        if (removeOptionParent !== undefined && optionParent !== undefined) {
          // if the parents match then only the particular child
          // is removed from the options array
          if (optionParent === removeOptionParent) return false
        }
        // this condition is satisfied for level 1 options
        if (removeOptionParent === optionParent) return false
      }
      if (get(item, 'options.length')) {
        return (item.options = this.removeOption(
          item.options,
          item.value,
          removeOption,
          removeOptionParent,
          name,
        )).length
      }
      return item
    })

  isOptionChecked = (values, optionValue, parent) => {
    if (parent) {
      return values.some((e = {}) => {
        if (e.value === parent) {
          return get(e, 'options', []).some(item => item?.value === optionValue)
        }
        if (e.options) return this.isOptionChecked(e.options, optionValue, parent)
        return false
      })
    }

    return values.some(e => e.value === optionValue)
  }

  renderOptionsSelected = values =>
    get(values, '[0].value') === null ? (
      <div
        className={`options-selected-container ${this.getClassName('options-selected-container')}`}
        onClick={event => event.stopPropagation()}
      >
        {this.renderSubOptionsSelected([values[0]])}
        <div
          onClick={() => this.removeSelectedGroup(values[0], true)}
          className={`remove-group ${this.getClassName('remove-group')}`}
        >
          &#10005;
        </div>
      </div>
    ) : (
      values.map((item, i) => (
        <div
          key={i}
          className={`options-selected-container ${this.getClassName('options-selected-container')}`}
          onClick={event => event.stopPropagation()}
        >
          {this.renderSubOptionsSelected([item])}
          <div
            onClick={() => this.removeSelectedGroup(item)}
            className={`remove-group ${this.getClassName('remove-group')}`}
          >
            &#10005;
          </div>
        </div>
      ))
    )

  renderSubOptionsSelected = data =>
    data.map((item, index) => {
      const { options = [] } = item || {}
      return (
        <React.Fragment key={`${item.value}-${index}`}>
          {!!options.length && (
            <div>
              <span
                className={`options-group ${this.getClassName(clasnameOptionsGroup)}`}
              >{` ${item?.label}`}</span>
              {options.length === 1 ? ` -> ${options[0]?.label}` : ` + ${options.length} Roles`}
              &nbsp;
            </div>
          )}
          {!options.length && (
            <div className={`options-value ${this.getClassName('options-value')}`}>
              <span className={`options-group ${this.getClassName(clasnameOptionsGroup)}`}>
                {item?.label}
              </span>
              &nbsp;
            </div>
          )}
        </React.Fragment>
      )
    })

  renderCaretButton = () => {
    const { isMenuOpen } = this.state

    return (
      <div className="multi-selector-button" onClick={this.toggleMenu}>
        <div
          className={
            isMenuOpen
              ? `arrow-up ${this.getClassName('arrow-up')}`
              : `arrow-down ${this.getClassName('arrow-down')}`
          }
        />
      </div>
    )
  }

  renderPlaceholder = () => {
    const { placeholder } = this.props

    return (
      <div className={`multi-selector-placeholder ${this.getClassName('multi-selector-placeholder')}`}>
        {placeholder || 'Select'}
      </div>
    )
  }

  renderOptionsMenu = (options, parent = {}) =>
    options.map((item, i) => {
      if (get(item, 'options')) {
        const { values } = this.state
        const checked = this.isOptionChecked(values, item.value, parent.value)
        return (
          <div key={`${item.value}-${i}`} className="options-container">
            <input
              type="checkbox"
              value={item.value}
              checked={checked}
              name={item.label}
              onChange={event => {
                const self = this
                if (!checked) {
                  findParentStructure(values, item, item.value, options, [], parent.value, data => {
                    self.selectOption(data, parent.value, event)
                  })
                } else {
                  self.selectOption({}, parent.value, event)
                }
              }}
            />
            <div className="checkbox">
              <span className="checkmark" />
            </div>
            <div className={`options-label ${this.getClassName('options-label')}`}>{item.label}</div>
            {this.renderSubMenu(item, parent, i)}
          </div>
        )
      }
      return (
        <React.Fragment key={`${get(item, 'value')}-${i}`}>{this.renderSubMenu(item, parent)}</React.Fragment>
      )
    })

  renderLoading = () => {
    return <div className="options-container">Searching...</div>
  }

  renderEmptyResults = () => {
    return <div className="options-container">Could not find any results.</div>
  }

  renderSubMenu = (item, parent = {}, highlighted) => {
    const { values } = this.state
    const { options, onChange, resetValues } = this.props
    const checked = this.isOptionChecked(values, item.value, parent.value)

    if (item.options) {
      return (
        <>
          <div className={`arrow-right ${this.getClassName('arrow-right')}`} />
          <div
            className={`options-sub-menu-container ${this.getClassName('options-sub-menu-container')} ${
              highlighted ? 'options-sub-menu-container-highlighted' : ''
            }`}
          >
            <div className={`options-sub-menu-header ${this.getClassName('options-sub-menu-header')}`}>
              {item.label}
            </div>
            {this.renderOptionsMenu(item.options, item)}
          </div>
        </>
      )
    }

    return (
      // eslint-disable-next-line jsx-a11y/label-has-associated-control
      (<label style={{ display: 'block' }}>
        <div className={`options-sub-menu ${this.getClassName('options-sub-menu')}`}>
          <input
            type="checkbox"
            value={item.value}
            checked={checked}
            name={item.label}
            onChange={event => {
              const self = this
              if (item.value === null) {
                if (checked) {
                  this.setState({ values: [] })
                  onChange([])
                } else {
                  this.setState({ values: this.state.filteredOptions })
                  onChange(resetValues ? [] : [{ value: null, label: 'Selected all' }])
                }
              } else if (!checked) {
                findParentStructure(values, item, item.value, options, [], parent.value, data => {
                  self.selectOption(data, parent.value, event)
                })
              } else {
                self.selectOption({}, parent.value, event)
              }
            }}
          />
          <div className="checkbox">
            <span className="checkmark" />
          </div>
          <div className={`options-label ${this.getClassName('options-label')}`}>{item.label}</div>
        </div>
      </label>)
    );
  }

  onSearch = event => {
    const { handleSearch } = this.props
    if (handleSearch) {
      handleSearch(event.target.value)
      this.setState({
        searchValue: event.target.value,
        isMenuOpen: true,
      })
    } else {
      this.setState({
        filteredOptions: filterItems(this.props.options, event.target.value),
        searchValue: event.target.value,
        isMenuOpen: true,
      })
    }
  }

  onArrowKeyNavigation = e => {
    if (e.key === 'Enter') {
      return this.setState(
        {
          values: this.state.filteredOptions[0]
            ? [...this.state.values, this.state.filteredOptions[0]]
            : this.state.values,
          searchValue: '',
          isMenuOpen: false,
          filteredOptions: this.props.options,
        },
        this.onOptionsChange,
      )
    }
  }

  render() {
    const { values, isMenuOpen, filteredOptions, searchValue } = this.state
    const { withSearch, placeholder, isLoading, deselectOption, scrollable, showEmptyResults } = this.props
    const numberOverheadValues = values.length > 2 ? values.length - 2 : 0
    const filteredOptionsWithDeselect = [{ value: null, label: 'Deselect all' }, ...filteredOptions]
    const classnameIsOpen = isMenuOpen
      ? `menu-open ${this.getClassName('menu-open')}`
      : `menu-close ${this.getClassName('menu-close')}`

    const optionsWithDeselect =
      !!values.length && deselectOption ? filteredOptionsWithDeselect : filteredOptions
    return (
      <div className="multi-level-selector-container">
        <div
          className={`multi-selector-container ${this.getClassName('multi-selector-container')} ${
            isMenuOpen ? `active ${this.getClassName('active')}` : 'inactive'
          }`}
        >
          <div className="multi-selector" onClick={this.toggleMenu}>
            {!values.length && !withSearch && this.renderPlaceholder()}
            {this.renderOptionsSelected(!!numberOverheadValues ? values.slice(0, 2) : values)}
            {withSearch && (
              <input
                value={searchValue}
                style={{ border: 'none', background: 'transparent', outline: 'none', minWidth: '30px' }}
                onChange={this.onSearch}
                onKeyDown={this.onArrowKeyNavigation}
                placeholder={values.length === 0 ? placeholder || 'Search' : ''}
                className="multi-selector-placeholder"
                type="text"
                ref={this.searchBox}
              />
            )}
            <div style={{ marginLeft: '5px', marginRight: '5px' }}>
              {!!numberOverheadValues && ` +${numberOverheadValues}`}
            </div>
          </div>
          {this.renderCaretButton()}
        </div>
        <div
          className={`multi-level-options-container ${this.getClassName(
            'multi-level-options-container',
          )} ${classnameIsOpen} ${scrollable ? 'scrollable' : ''}`}
        >
          <div className="options-main-menu">
            {isLoading ? this.renderLoading() : this.renderOptionsMenu(optionsWithDeselect)}
            {!isLoading &&
              searchValue !== '' &&
              showEmptyResults &&
              filteredOptions.length === 0 &&
              this.renderEmptyResults()}
          </div>
        </div>
      </div>
    )
  }
}

FilterHierarchyMultiselect.propTypes = {
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  handleSearch: PropTypes.func,
  withSearch: PropTypes.bool,
  deselectOption: PropTypes.bool,
  resetValues: PropTypes.bool,
  isLoading: PropTypes.bool,
  refreshValues: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.string.isRequired,
          label: PropTypes.string.isRequired,
        }),
      ),
    }),
  ),
  className: PropTypes.string,
  values: PropTypes.array,
  scrollable: PropTypes.bool,
  showEmptyResults: PropTypes.bool,
}

FilterHierarchyMultiselect.defaultProps = {
  placeholder: '',
  options: [],
  onChange: () => {},
  className: '',
  values: [],
  withSearch: false,
  isLoading: false,
  refreshValues: true,
  resetValues: false,
  deselectOption: false,
  scrollable: false,
  handleSearch: null,
  showEmptyResults: false,
}

export default listensToClickOutside(FilterHierarchyMultiselect)
