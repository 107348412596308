// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { withTrackingTriggerHoc } from 'syft-acp-util/withTrackingTriggerHoc'

import { Button, ButtonLink } from 'syft-acp-atoms/Button'
import { showConfirmModal } from 'syft-acp-core/store/modals/actions'
import * as actionTypes from 'syft-acp-core/store/employer-venues/actions'
import RecordControls from 'syft-acp-core/components/RecordSection/RecordControls'
import LoadingIndicator from 'syft-acp-util/components/LoadingIndicator'
import { trackingEvents } from './EmployerVenueDetail.tracking'

/**
 * Form with entity detail actions, such as ban user.
 *
 * @param {Object} props Properties
 * @returns {XML} Pure EntityDetailControls component
 */
class EmployerVenueDetailControls extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    employerID: PropTypes.number.isRequired,
    venueID: PropTypes.number.isRequired,
    isPristine: PropTypes.bool.isRequired,
    isSavingData: PropTypes.bool.isRequired,
    actions: PropTypes.objectOf(PropTypes.func).isRequired,
    isLoadingData: PropTypes.bool,
    triggerEvent: PropTypes.func.isRequired,
  }

  static defaultProps = {
    isLoadingData: false,
  }

  saveAction = () => {
    const { actions, employerID, data, triggerEvent } = this.props
    actions.saveEmployerVenue(employerID, data.id, data)
    if (!data.id) {
      triggerEvent(trackingEvents.CREATE_BUTTON.CLICKED)
    } else {
      triggerEvent(trackingEvents.SAVE_BUTTON.CLICKED)
    }
  }

  deleteAction = () => {
    const { actions, employerID, data, triggerEvent } = this.props

    actions.showConfirmModal({
      question: 'Are you sure? This venue will be permanently deleted.',
      onConfirm: () => {
        actions.deleteEmployerVenue(employerID, data.id)
        triggerEvent(trackingEvents.DELETE_BUTTON.CLICKED)
      },
    })
  }

  render() {
    return (
      <RecordControls>
        <div>
          <ButtonLink to={`/entity/employers/view/${this.props.employerID}/venues/list`}>
            Back to venue list
          </ButtonLink>{' '}
          <ButtonLink
            to={`/entity/employers/view/${this.props.employerID}/ratecards/list/venue/${this.props.venueID}`}
          >
            View rate cards
          </ButtonLink>
        </div>
        <div className="right">
          <LoadingIndicator loadingState={this.props.isSavingData || this.props.isLoadingData} />{' '}
          <Button
            kind="success"
            onClick={this.saveAction}
            disabled={this.props.isSavingData || this.props.isPristine}
          >
            Save
          </Button>{' '}
          <Button kind="danger" onClick={this.deleteAction}>
            Delete
          </Button>
        </div>
      </RecordControls>
    )
  }
}

export default connect(
  ({ employerVenues: { isSavingData, isLoadingData } }) => ({
    isSavingData,
    isLoadingData,
  }),
  dispatch => ({
    actions: bindActionCreators({ ...actionTypes, showConfirmModal }, dispatch),
  }),
)(withTrackingTriggerHoc(EmployerVenueDetailControls))
