// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'

import PageView from 'syft-acp-core/components/PageView'
import LoginForm, { LoginSSOForm } from 'syft-acp-core/components/LoginForm'
import BrowserNotice from 'syft-acp-core/components/BrowserNotice'
import { LoginProps as Props } from './Login.types'
import S from './Login.module.scss'
import config from 'syft-acp-core/config'
const colSizes = { mdOffset: 3, md: 4, smOffset: 3, sm: 6, xsOffset: 1, xs: 10 }

export const Login = (props: Props) => {
  return (
    <div className={S['login-page-container']}>
      <PageView {...props} container>
        <BrowserNotice />
      </PageView>
      <PageView customColSizes={colSizes} {...props} container narrow>
        {config.isPrivileged && config.isLegacyLoginFlow ? <LoginForm /> : <LoginSSOForm />}
      </PageView>
    </div>
  )
}
