import { TYPE_MULTI_ASSOCIATIVE_ENUM } from 'syft-acp-core/components/EditableTable'
import { EmployerPreferenceEntity } from 'syft-acp-core/store/employerPreferences/types'
import { get, partialRight } from 'lodash-es'

const neverOption = {
  value: 'never',
  label: 'Never',
  child: [{ value: 'open_to_discussion', label: 'Head office open to further discussion' }],
}

export const PREFERENCE_STRUCTURE_KEYS = {
  rates: 'rates',
  booking: 'booking',
  worker_pool: 'worker_pool',
  overbooking: 'overbooking',
  flex_offer: 'flex_offer',
}

const buildSection = <T extends { options: Record<string, any>[] }>(
  name: string,
  options: T,
  data: EmployerPreferenceEntity,
  root: boolean,
) => [
  name,
  {
    ...options,
    editable: root || get(data, name)?.key !== 'never',
  },
]

export const preferencesStructure = (
  data: EmployerPreferenceEntity,
  root: boolean,
  enableCascadeOverbookPreferences: boolean,
) => {
  const build = partialRight(buildSection, data, root)
  const overbookingPreferences = enableCascadeOverbookPreferences
    ? {
        [PREFERENCE_STRUCTURE_KEYS.overbooking]: [
          build('details.allow_overbooking', {
            header: 'Allow overbooking',
            options: [
              {
                value: 'flexible',
                label: 'Flexible',
              },
              { ...neverOption, child: [] },
            ],
            type: TYPE_MULTI_ASSOCIATIVE_ENUM,
          }),
        ],
      }
    : {
        [PREFERENCE_STRUCTURE_KEYS.overbooking]: [
          build('details.allow_overbooking', {
            header: 'Allow overbooking',
            options: [
              {
                value: 'flexible',
                label: 'Flexible',
              },
              neverOption,
            ],
            type: TYPE_MULTI_ASSOCIATIVE_ENUM,
          }),
        ],
      }

  return {
    ...overbookingPreferences,
    [PREFERENCE_STRUCTURE_KEYS.rates]: [
      build('details.increase_rate', {
        header: 'Increase rate',
        options: [
          {
            value: 'flexible',
            label: 'Flexible',
            child: [],
          },
          {
            ...neverOption,
            child: [],
          },
        ],
        type: TYPE_MULTI_ASSOCIATIVE_ENUM,
      }),
    ],
    [PREFERENCE_STRUCTURE_KEYS.booking]: [
      build('details.allow_individual_shift_booking', {
        header: 'Allow individual shift booking',
        options: [
          {
            value: 'flexible',
            label: 'Flexible',
          },
          neverOption,
        ],
        type: TYPE_MULTI_ASSOCIATIVE_ENUM,
      }),
    ],
    [PREFERENCE_STRUCTURE_KEYS.worker_pool]: [
      build('details.worker_pool', {
        header: 'Broaden the Flexer pool',
        options: [
          {
            value: 'flexible',
            label: 'Flexible',
            child: [
              { value: 'all_flexers', label: 'Offer to all Flexers' },
              { value: 'my_top_rated_flexers', label: 'Offer to my 5★ Flexer pool' },
            ],
          },
          neverOption,
        ],
        type: TYPE_MULTI_ASSOCIATIVE_ENUM,
      }),
    ],
    [PREFERENCE_STRUCTURE_KEYS.flex_offer]: [
      build('details.flex_offer', {
        header: 'Default offer for flex in the cascade',
        options: [
          {
            value: 'enabled',
            label: 'Let Flex do the work',
          },
          {
            value: 'disabled',
            label: `Don't offer to flex`,
          },
        ],
        type: TYPE_MULTI_ASSOCIATIVE_ENUM,
      }),
    ],
  }
}
