import React, { useCallback, useEffect, useState } from 'react'

import PropTypes from 'prop-types'
import { Grid, Col, Row } from 'react-bootstrap'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { useFlexFlagIsOn } from '@indeed/flex-feature-flags'
import { useIntl } from 'react-intl'
import { get } from 'lodash-es'
import { TrackingTrigger } from '@indeed/flex-tracking-context'

import { Button } from 'syft-acp-atoms/Button'
import entityDetail from 'syft-acp-util/entityDetail'
import { withTrackingTriggerHoc } from 'syft-acp-util/withTrackingTriggerHoc'
import { publishListingToAgency } from 'syft-acp-core/store/listings/actions'
import SiteComponent from 'syft-acp-core/components/SiteComponent'
import RecordSection from 'syft-acp-core/components/RecordSection'
import NotifyBar from 'syft-acp-atoms/NotifyBar'
import CountrySpecific from 'syft-acp-core/components/CountrySpecific'
import { SUPPORTED_COUNTRY_CODES } from 'syft-acp-core/lib/i18n'
import ListingDetailControls from './ListingDetailControls'
import ListingShiftTabs from './ListingShiftTabs'
import { dataStructure } from './structure'
import { trackingEvents } from './tracking'
import { fetchAdminJob } from 'syft-acp-core/store/jobs/actions'
import { resetAllTabs } from 'syft-acp-core/store/data-tabs/actions'

import './ListingDetail.css'

export const ListingDetail = ({
  data,
  isPristine,
  actionUpdate,
  id,
  jobID,
  shiftID,
  actions,
  triggerEvent,
  selectedVenueId,
}) => {
  const jobIdInitial = jobID || data?.jobs?.[0]?.id
  const [modifiedData, setModifiedData] = useState({})
  const { countryCode } = useIntl()

  const isUS = countryCode === SUPPORTED_COUNTRY_CODES.US
  const hasJobOverbookTags = useFlexFlagIsOn('pte_39531_acp_overbook_tag')

  useEffect(() => {
    if (jobID && actions.fetchAdminJob) {
      actions.fetchAdminJob(jobID)
    } else if (!jobID && jobIdInitial) {
      actions.fetchAdminJob(jobIdInitial)
    }
    actions.resetAllTabs()
  }, [actions, jobID, jobIdInitial])

  const handleUpdatedData = (name, value, ...rest) => {
    setModifiedData({ ...modifiedData, [name]: value })
    actionUpdate(name, value, ...rest)
  }

  const handlePostListingToAgency = useCallback(() => {
    triggerEvent(trackingEvents.LISTING_INFO.POST_LISTING_TO_AGENCY.CLICKED, { id })
    return actions.publishListingToAgency({ id })
  }, [actions, id, triggerEvent])

  return (
    <SiteComponent>
      <TrackingTrigger onLoad={{ event: trackingEvents.LISTING_INFO.PAGE.VIEWED }} />
      <Grid className="listing-detail">
        <Row>
          <Col md={12} sm={12}>
            <ListingDetailControls
              modifiedData={modifiedData}
              hasControlButtons
              data={data}
              isPristine={isPristine}
              shiftID={shiftID}
            />
            <CountrySpecific countries={[SUPPORTED_COUNTRY_CODES.US]}>
              <NotifyBar conditional visible={data?.employer?.no_admin_input}>
                No admin input
              </NotifyBar>
            </CountrySpecific>
            <RecordSection
              header={
                <div className="item-header listing-header">
                  <h2>Listing information</h2>
                  {data?.venue?.requires_agency && (
                    <Button kind="primary" onClick={handlePostListingToAgency}>
                      Post Listing to Agency
                    </Button>
                  )}
                </div>
              }
              data={data}
              structure={dataStructure(selectedVenueId).profile}
              update={handleUpdatedData}
            />
            <ListingShiftTabs
              jobID={jobID}
              isUS={isUS}
              hasJobOverbookTags={hasJobOverbookTags}
              data={data}
              listingID={id}
              activeJobID={jobIdInitial}
              activeShiftID={shiftID}
            />
          </Col>
        </Row>
      </Grid>
    </SiteComponent>
  )
}

ListingDetail.propTypes = {
  id: PropTypes.number,
  jobID: PropTypes.number,
  shiftID: PropTypes.number,
  data: PropTypes.object,
  actionUpdate: PropTypes.func.isRequired,
  triggerEvent: PropTypes.func.isRequired,
  isPristine: PropTypes.bool,
  actions: PropTypes.object,
}

ListingDetail.defaultProps = {
  id: 0,
  jobID: 0,
  shiftID: 0,
  data: {},
  isPristine: true,
  actions: {},
}

export default connect(
  state => ({
    selectedVenueId: get(state, 'dataTabs.venue_id'),
  }),
  dispatch => ({
    actions: bindActionCreators({ publishListingToAgency, fetchAdminJob, resetAllTabs }, dispatch),
  }),
)(withTrackingTriggerHoc(entityDetail('listings', ListingDetail)))
