import { takeLatest } from 'redux-saga/effects'
import * as api from 'syft-acp-core/api/resources/admin/commissions'
import {
  STOP_COMMISSION_SUCCEEDED,
  STOP_COMMISSION_FAILED,
  COMMISSION_CREATE_FAILED,
  COMMISSION_CREATE_SUCCEEDED,
} from 'syft-acp-core/actions/action-types'

import { takeLatestApiCall } from './helpers'
import { notificationCall } from './calls'

export default function* root() {
  yield takeLatestApiCall('STOP_COMMISSION', api.stopCommission)
  yield takeLatestApiCall('COMMISSION_CREATE', api.createCommission)
  yield takeLatest(
    [
      STOP_COMMISSION_SUCCEEDED,
      STOP_COMMISSION_FAILED,
      COMMISSION_CREATE_FAILED,
      COMMISSION_CREATE_SUCCEEDED,
    ],
    notificationCall,
  )
}
