import React from 'react'
import SubNavigation, { SubLink } from 'syft-acp-atoms/SubNavigation'

const AgenciesSubNavigation = () => {
  return (
    <SubNavigation>
      <SubLink to="/agencies/list">Active agencies</SubLink>
      <SubLink to="/agencies/onboarding">Onboarding agencies</SubLink>
    </SubNavigation>
  )
}

export default AgenciesSubNavigation
