import { apiRequest, handleCall } from 'syft-acp-core/api/call'

export const stopCommission = ({ options: reqArgs }) =>
  new Promise((resolve, reject) => {
    const req = apiRequest({ path: '/admin/sales/manager_commissions/stop', reqArgs, method: 'PATCH' })
    handleCall(req, resolve, reject)
  })

export const createCommission = ({ options: reqArgs }) =>
  new Promise((resolve, reject) => {
    const req = apiRequest({ path: '/admin/sales/manager_commissions', reqArgs, method: 'POST' })
    handleCall(req, resolve, reject)
  })
