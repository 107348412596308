import {
  UpdateAgenciesRanks,
  RemoveFromAgenciesByRankValue,
  MakeAgenciesOptions,
  FilterSelectedFromOptions,
  DataAgenciesDropdownResult,
} from './DataAgenciesDropdown.types'

// Update and prevent duplicity in agency ids, in case user wants to change order of priority
export const updateAgenciesRanks = ({
  results,
  rank,
  value,
  id,
  type,
  data,
}: UpdateAgenciesRanks): DataAgenciesDropdownResult[] => {
  const remainingResults = results.filter(agency => agency.rank !== rank && agency.agency_id !== value)
  const findExistingAgency = results.find(agency => agency.agency_id === value)
  const findPreviousAgency = results.find(agency => agency.rank === rank)
  const remainingResultsUpdated = remainingResults.map(item => {
    if (findExistingAgency && item.rank < rank && item.rank > findExistingAgency?.rank) {
      return {
        ...item,
        rank: item.rank - 1,
      }
    }
    if (findExistingAgency && item.rank > rank && item.rank < findExistingAgency?.rank) {
      return {
        ...item,
        rank: item.rank + 1,
      }
    }
    return item
  })
  if (!!findExistingAgency) {
    return [
      ...remainingResultsUpdated,
      ...(findPreviousAgency
        ? [
            {
              id: findPreviousAgency.id,
              flexible: findPreviousAgency.flexible,
              agency_id: findPreviousAgency.agency_id || value,
              agency_name: findPreviousAgency.agency_name,
              rank: rank > findExistingAgency?.rank ? rank - 1 : rank + 1,
              work_location_id: data.id,
            },
          ]
        : []),
      ...(findExistingAgency
        ? [
            {
              id: findExistingAgency.id || id,
              flexible: findExistingAgency.flexible,
              agency_id: findExistingAgency.agency_id || value,
              agency_name: findExistingAgency.agency_name,
              rank: rank,
              work_location_id: data.id,
            },
          ]
        : []),
    ].sort((first, second) => first.rank - second.rank)
  }
  return [
    ...remainingResults,
    {
      id,
      agency_id: value,
      rank,
      work_location_id: data.id,
      flexible: type !== 'primary',
    },
  ].sort((first, second) => first.rank - second.rank)
}

export const removeFromAgenciesByRankValue = ({ results, rank }: RemoveFromAgenciesByRankValue) => {
  return results
    .filter(agency => agency.rank !== rank)
    .map(agency => (agency.rank > rank ? { ...agency, rank: agency.rank - 1 } : agency))
}

export const makeAgenciesOptions = ({ agencies }: MakeAgenciesOptions): { id: number; label: string }[] => {
  if (!agencies) {
    return []
  }

  return Object.values(agencies).map(agency => ({
    id: agency.id,
    label: agency.name,
  }))
}

export const filterSelectedFromOptions = ({ options, values, selected }: FilterSelectedFromOptions) => {
  return options.filter(
    option => !values?.find(({ agency_id: agencyId }) => agencyId === option.id && option.id !== selected),
  )
}

export const isAgencyArray = (value: unknown): value is DataAgenciesDropdownResult[] => {
  return (
    Array.isArray(value) &&
    value.every(
      item =>
        typeof item === 'object' &&
        item !== null &&
        'agency_id' in item &&
        'rank' in item &&
        'work_location_id' in item,
    )
  )
}

export const filterByFlexible = (type: 'primary' | 'other') => (value: DataAgenciesDropdownResult) => {
  if (type === 'primary') {
    return !value.flexible
  } else if (type === 'other') {
    return value.flexible === true
  }
  return false
}
