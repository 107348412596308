import { cloneDeep, find, get, has, keyBy, set, uniq } from 'lodash-es'
import { shiftBookingsList } from 'syft-acp-core/reducers/entities/entitySets/shiftBookings'
import { entitySetName } from 'syft-acp-core/reducers/generators/utils'
import { updatedEntityMapChanges } from '../entities/entityMapChanges/entityMapChanges'
import { updatedShiftBookingEntityMap } from '../entities/entityMaps/shiftBookingEntityMaps'

export const draftKey = 'Spot'

export const failedBookingState = (state, action) => {
  const {
    payload: {
      message,
      response: { status },
      body,
    },
  } = action
  const error_description = get(body, 'error_description') || get(body, 'debug.message')
  const setKey = entitySetName({ shiftID: action.payload.options.shiftID })

  return {
    ...state,
    isSavingData: false,
    lastMessage: String(message),
    lastStatus: String(status),
    lastBody: {
      error_description: String(error_description),
    },
    entitySets: {
      ...state.entitySets,
      [setKey]: {
        ...state.entitySets[setKey],
        isLoadingData: false,
      },
    },
  }
}

export const adminListingShiftFetchBeginState = (state, action) => {
  const { shiftID } = action
  const setKey = entitySetName({ shiftID })

  return {
    ...state,
    lastMessage: '',
    lastStatus: null,
    lastBody: null,
    entitySets: {
      ...state.entitySets,
      [setKey]: {
        isLoadingData: true,
        ids: [],
      },
    },
    originalEntityMap: {},
    entityMapChanges: {},
    isSavingData: false,
    isLoadingData: true,
  }
}

export const adminListingShiftFetchSucceededState = (state, action) => {
  const {
    payload,
    request: { shiftID, jobID, options },
    page,
    perPage,
    total,
    totalPages,
    nextPage,
  } = action
  const timezone = get(
    find(state.entityMap, entity => has(entity, 'timezone')),
    'timezone',
  )

  const shiftBookings = shiftBookingsList({ bookings: payload, shiftID, jobID, timezone, page, perPage })
  const idKey = 'id'
  const setKey = entitySetName({ shiftID })
  const entityMap = {
    ...state.entityMap,
    ...keyBy(shiftBookings, idKey),
  }
  const originalEntityMap = cloneDeep(entityMap)

  return {
    ...state,
    lastMessage: '',
    lastStatus: null,
    lastBody: null,
    entitySets: {
      ...state.entitySets,
      [setKey]: {
        isLoadingData: false,
        ids: shiftBookings.map(sb => sb.id),
        page,
        perPage,
        total,
        totalPages,
        nextPage,
        activePage: options.page,
      },
    },
    isLoadingData: false,
    entityMap,
    originalEntityMap,
  }
}

export const bookingsDeleteSucceededState = (state, action) => {
  const { id, shiftID } = action.payload
  const setKey = entitySetName({ shiftID })
  const ids = state.entitySets[setKey].ids.filter(id_ => id_ !== id)

  return {
    ...state,
    lastMessage: '',
    lastStatus: null,
    lastBody: null,
    entitySets: {
      ...state.entitySets,
      [setKey]: {
        ...state.entitySets[setKey],
        isLoadingData: false,
        ids,
        total: state.entitySets[setKey].total - 1,
        perPage: state.entitySets[setKey].perPage - 1,
      },
    },
  }
}

export const bookingsAddState = (state, action) => {
  const id = draftKey
  const { jobID, shiftID, startTime, endTime } = action.payload
  const draftShiftBooking = { id, jobID, shiftID, startTime, endTime }
  set(draftShiftBooking, 'break.duration', 0)
  set(draftShiftBooking, 'fee_percent', 0)
  set(draftShiftBooking, 'worker.id', '')
  const setKey = entitySetName({ shiftID })
  const ids = uniq([id].concat(state.entitySets[setKey].ids))
  const entityMap = {
    ...state.entityMap,
    [id]: draftShiftBooking,
  }

  return {
    ...state,
    lastMessage: '',
    lastStatus: null,
    lastBody: null,
    entitySets: {
      ...state.entitySets,
      [setKey]: {
        isLoadingData: false,
        ids,
      },
    },
    entityMap,
  }
}

export const bookingUpdateState = (state, action) => {
  const entityMap = updatedShiftBookingEntityMap(state.entityMap, action.payload)

  return {
    ...state,
    lastMessage: '',
    lastStatus: null,
    lastBody: null,
    entityMap,
  }
}

export const shiftEditFailedState = (state, action) => {
  const fields = get(action.payload.body, 'error_details.fields')
  const desc = fields && fields.map(f => `${f.field} ${f.message}`).join(', ')
  const descMsg = desc ? ` (${desc})` : ''

  return {
    ...state,
    isSavingData: false,
    lastMessage: action.payload.message,
    lastStatus: action.payload.response.status,
    lastBody: {
      error_description: action.payload.body.error_description + descMsg,
    },
  }
}

export const removeAdminShiftsSpotSucceededState = (state, action) => {
  const setKey = entitySetName({ shiftID: action.payload.shiftID })
  const { entitySets } = state
  const { id } = action.payload
  const ids = entitySets[setKey].ids.filter(id_ => id_ !== id)

  return {
    ...state,
    lastMessage: '',
    lastStatus: null,
    lastBody: null,
    entitySets: {
      ...entitySets,
      [setKey]: {
        ...entitySets[setKey],
        isLoadingData: false,
        ids,
        total: state.entitySets[setKey].total - 1,
        perPage: state.entitySets[setKey].perPage - 1,
      },
    },
  }
}

export const bookingsUpdateChangesState = (state, action) => {
  const { id, attr, val } = action.payload
  const isTimePunchesArray = attr.split('.')[0] === 'time_punches'

  const { entityMapChanges, originalEntityMap } = state

  const originalVal = get(originalEntityMap, `[${id}].${attr}`)
  const entityMapChangeVal = get(state.entityMap, `[${id}].update.${attr}`)

  const params = { attr, val, originalVal: entityMapChangeVal !== val ? entityMapChangeVal : originalVal }
  const changes = get(entityMapChanges, `[${id}].update`, {})
  const updatedChanges = updatedEntityMapChanges(changes, params)

  let createChanges = state.entityMapChanges[id]?.create
  if (
    updatedChanges.hasOwnProperty('provisional') &&
    createChanges &&
    Object.keys(createChanges).length > 0
  ) {
    createChanges = {
      ...createChanges,
      provisional: updatedChanges.provisional,
    }
    delete updatedChanges.provisional
  }

  // Workaround for Immer
  let normalizedUpdatedChanges = updatedChanges
  if (isTimePunchesArray) {
    const timePunches = get(originalEntityMap, `[${id}].time_punches`)

    normalizedUpdatedChanges = [...timePunches]
    Object.entries(updatedChanges).forEach(([key, value]) => {
      set(normalizedUpdatedChanges, key, value)
    })
  }

  return {
    ...state,
    entityMapChanges: {
      ...state.entityMapChanges,
      [id]: {
        ...entityMapChanges[id],
        update: normalizedUpdatedChanges,
        create: createChanges,
      },
    },
  }
}

export const bookingsCancelChangesState = (state, action) => {
  const { id } = action.payload
  const cancelChanges = true

  return {
    ...state,
    entityMapChanges: {
      ...state.entityMapChanges,
      [id]: {
        ...state.entityMapChanges[id],
        cancel: cancelChanges,
      },
    },
  }
}

export const bookingsRemoveChangesState = (state, action) => {
  const { id } = action.payload
  const removeChanges = true

  return {
    ...state,
    entityMapChanges: {
      ...state.entityMapChanges,
      [id]: {
        ...state.entityMapChanges[id],
        remove: removeChanges,
      },
    },
  }
}

export const bookingsCreateChangesState = (state, action) => {
  const { id, attr, val } = action.payload
  const { entityMapChanges, originalEntityMap } = state

  const originalVal = get(originalEntityMap, `[${id}].${attr}`)
  const params = { attr, val, originalVal }
  const changes = get(entityMapChanges, `[${id}].create`, {})
  const createChanges = updatedEntityMapChanges(changes, params)

  return {
    ...state,
    entityMapChanges: {
      ...state.entityMapChanges,
      [id]: {
        ...state.entityMapChanges[id],
        create: createChanges,
      },
    },
  }
}

export const bookingsReplaceChangesState = (state, action) => {
  const { id, attr, val } = action.payload
  const { entityMapChanges, originalEntityMap } = state

  const originalVal = get(originalEntityMap, `[${id}].${attr}`)
  const params = { attr, val, originalVal }
  const changes = get(entityMapChanges, `[${id}].replace`, {})
  const createChanges = updatedEntityMapChanges(changes, params)

  return {
    ...state,
    entityMapChanges: {
      ...state.entityMapChanges,
      [id]: {
        ...state.entityMapChanges[id],
        replace: createChanges,
      },
    },
  }
}
