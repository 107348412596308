import { eq, get } from 'lodash-es'
import { toLocalISO8601 } from 'syft-acp-util/formatting'

const findBuilkEditParam = (acc, changedEntity) => {
  const {
    shiftID,
    worker: { id },
  } = changedEntity
  const params = acc.find(param => {
    const paramWorkerID = get(param, 'worker_id')
    const paramShiftID = param.shift_id
    const isShiftIdEq = eq(paramShiftID, shiftID)
    const isWorkerIdEq = eq(paramWorkerID, id)
    const isFound = isShiftIdEq && isWorkerIdEq

    return isFound ? param : false
  })

  if (params) {
    return params
  } else {
    const baseParam = {
      shift_id: shiftID,
      worker_id: id,
    }
    acc.push(baseParam)
    return baseParam
  }
}

/**
 * In order to update the unpaid time for a worker, we will destroy whatever unpaid time was previously input.
 * If an ID exists, we will mark it for deletion, so that only our new time remains.
 * This is to make it technically possible to add multiple corrections. But in practice we don't.
 *
 * All existing times are marked for deletion, all not yet saved are deleted and replaced by 'newValue'.
 *
 * See <https://syftapp.atlassian.net/wiki/spaces/SV/pages/48201731/Admin+Bulk+Edit+Timesheet+API>.
 * JIRA: <https://syftapp.atlassian.net/browse/API2-1319>.
 */
const replaceUnpaidTimes = (unpaidTimes, newValue) =>
  unpaidTimes
    ? [
        ...unpaidTimes.reduce((acc, curr) => (curr.id ? [...acc, { ...curr, _destroy: true }] : acc), []),
        newValue,
      ]
    : [newValue]

export const enrichBulkEditParams = (acc, attr, value, changedEntity, caMealBreakFlag) => {
  const bulkEditParam = findBuilkEditParam(acc, changedEntity)
  switch (attr) {
    case 'client_time.clock_in':
      bulkEditParam.clock_in_time = caMealBreakFlag ? value : toLocalISO8601(value)
      bulkEditParam.subject = 'client'
      break
    case 'client_time.clock_out':
      bulkEditParam.clock_out_time = caMealBreakFlag ? value : toLocalISO8601(value)
      bulkEditParam.subject = 'client'
      break
    case 'client_time.break_duration':
      bulkEditParam.break_duration = value
      bulkEditParam.subject = 'client'
      break
    case 'worker_time.clock_in':
      bulkEditParam.clock_in_time = caMealBreakFlag ? value : toLocalISO8601(value)
      bulkEditParam.subject = 'worker'
      break
    case 'worker_time.clock_out':
      bulkEditParam.clock_out_time = caMealBreakFlag ? value : toLocalISO8601(value)
      bulkEditParam.subject = 'worker'
      break
    case 'worker_time.break_duration':
      bulkEditParam.break_duration = value
      bulkEditParam.subject = 'worker'
      break
    case 'clock_in.time':
      bulkEditParam.clock_in_time = caMealBreakFlag ? value : toLocalISO8601(value)
      break
    case 'clock_out.time':
      bulkEditParam.clock_out_time = caMealBreakFlag ? value : toLocalISO8601(value)
      break
    case 'break.duration':
      bulkEditParam.break_duration = value
      break
    case 'break_details':
      bulkEditParam.break_params = value
      break
    case 'time_punches':
      bulkEditParam.time_punches = changedEntity.time_punches
      break
    case 'unpaid_times_n':
    case 'unpaid_times':
      // This is the array of unpaid times objects from the API, still untouched.
      const pristineUnpaidTimes = changedEntity.unpaid_times
      // Don't add an unpaid times value if the desired value is zero and there is nothing set.
      if (changedEntity.unpaid_times.length === 0 && value === 0) {
        return
      }
      if (!changedEntity.unpaid_times) changedEntity.unpaid_times = []
      // Destroy all previous instances of 'unpaid_time' and replace it with the new value.
      bulkEditParam.unpaid_times = replaceUnpaidTimes(pristineUnpaidTimes, { duration: value })
      break
    case 'worker_pay_rate':
      bulkEditParam.worker_pay_rate = value
      break
    case 'worker_pay_rate.amount':
      bulkEditParam.worker_pay_rate = value
      break
    case 'no_show':
      bulkEditParam.no_show = value
      break
    case 'no_show_reason':
      bulkEditParam.no_show_reason = value
      break
    case 'syft_unpaid_hours_compensation':
      bulkEditParam.syft_unpaid_hours_compensation = value
      break
    case 'syft_compensation':
      bulkEditParam.syft_compensation = value
      break
    case 'syft_compensation_reason':
      bulkEditParam.syft_compensation_reason = value
      break
    case 'syft_compensation_approver':
      bulkEditParam.syft_compensation_approver = value
      break
    case 'fee_percent':
      bulkEditParam.fee_percent = value
      break
    case 'fee_not_charged':
      bulkEditParam.fee_not_charged = value
      break
    case 'meal_details':
      bulkEditParam.meal_details = value
      break
    default:
      break
  }
}
