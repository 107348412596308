import { apiRequest, handleCall } from 'syft-acp-core/api/call'

import * as types from './types'

/**
 * Retrieves a list of employer venues.
 *
 * @param params {Object}
 * @param params.employerID {Object} the employer ID
 * @param params.toCSV {Boolean} whether or not to fetch a CSV
 */
export const listEmployerVenues = ({ options = {}, toCSV }: types.ListEmployerVenuesInput) =>
  new Promise((resolve, reject) => {
    let req
    const { employerID, ...reqArgs } = options
    if (toCSV) {
      req = apiRequest({ path: `/admin/employers/${employerID}/venues.csv`, reqArgs, returnAll: toCSV })
    } else {
      req = apiRequest({ path: `/employers/${employerID}/venues_paginated`, reqArgs })
    }
    // Last flag means that CSV is the stream
    handleCall(req, resolve, reject, true, reqArgs, null, toCSV, 'employer-venues', toCSV)
  })

/**
 * Retrieves a list of employer venues for filters.
 *
 * @param params {Object}
 * @param params.employerID {Object} the employer ID
 */
export const listEmployerVenuesFiltered = ({ employerID }: types.ListEmployerVenuesFilteredInput) =>
  new Promise((resolve, reject) => {
    const req = apiRequest({ path: `/employers/${employerID}/venues_for_filters` })
    handleCall(req, resolve, reject, true, { employerID })
  })

/**
 * Retrieves a list of employer venue names.
 *
 * @param params {Object}
 * @param params.employerID {Object} the employer ID
 */
export const listEmployerVenueNames = ({ employerID }: types.ListEmployerVenueNamesInput) =>
  new Promise((resolve, reject) => {
    const req = apiRequest({ path: `/admin/employers/${employerID}/venues` })
    handleCall(req, resolve, reject, true, { employerID })
  })

/**
 * Retrieves an employer venue.
 *
 * @param params {Object}
 * @param params.employerID {Object} the employer ID
 * @param params.venueID {Object} the venue ID
 */
export const fetchEmployerVenue = ({ employerID, venueID }: types.FetchEmployerVenueInput) =>
  new Promise((resolve, reject) => {
    const req = apiRequest({ path: `/admin/employers/${employerID}/venues/${venueID}` })
    handleCall(req, resolve, reject, true)
  })

/**
 * Saves an employer venue or creates a new one.
 *
 * @param params {Object}
 * @param params.employerID {Number} Employer ID
 * @param params.id {Number} Area ID
 * @param params.data {Object} Request arguments
 */
export const saveEmployerVenue = ({ employerID, id, data }: types.SaveEmployerVenueInput) =>
  new Promise((resolve, reject) => {
    let req
    if (id) {
      req = apiRequest({
        path: `/employers/${employerID}/venues/${id}`,
        reqArgs: data,
        method: 'PUT',
        allowNulls: true,
      })
    } else {
      req = apiRequest({ path: `/employers/${employerID}/venues`, reqArgs: data, method: 'POST' })
    }
    handleCall(req, resolve, reject, true)
  })

/**
 * Saves an area for an employer venue or creates a new one.
 *
 * @param params {Object}
 * @param params.employerID {Number} Employer ID
 * @param params.venueID {Number} Venue ID
 * @param params.id {Number} Area ID
 * @param params.data {Object} Request arguments
 */
export const saveEmployerVenueArea = ({ employerID, venueID, id, data }: types.SaveEmployerVenueAreaInput) =>
  new Promise((resolve, reject) => {
    let req
    if (id) {
      req = apiRequest({
        path: `/employers/${employerID}/venues/${venueID}/areas/${id}`,
        reqArgs: data,
        method: 'PUT',
      })
    } else {
      req = apiRequest({
        path: `/employers/${employerID}/venues/${venueID}/areas`,
        reqArgs: data,
        method: 'POST',
      })
    }
    handleCall(req, resolve, reject, true)
  })

/**
 * Deletes an area for an employer venue.
 *
 * @param params {Object}
 * @param params.employerID {Number} Employer ID
 * @param params.venueID {Number} Venue ID
 * @param params.id {Number} Area ID
 */
export const deleteEmployerVenueArea = ({ employerID, venueID, id }: types.DeleteEmployerVenueAreaInput) =>
  new Promise((resolve, reject) => {
    const req = apiRequest({
      path: `/employers/${employerID}/venues/${venueID}/areas/${id}`,
      method: 'DELETE',
    })
    handleCall(req, resolve, reject, true)
  })

/**
 * Delete an employer venue.
 *
 * @param params {Object}
 * @param params.employerID {Number} ID of the employer
 * @param params.id {Number} ID of the venue
 */
export const deleteEmployerVenue = ({ employerID, id }: types.DeleteEmployerVenueInput) =>
  new Promise((resolve, reject) => {
    const req = apiRequest({ path: `/employers/${employerID}/venues/${id}`, method: 'DELETE' })
    handleCall(req, resolve, reject, true)
  })
