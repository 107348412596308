import { fork, put, select, takeLatest } from 'redux-saga/effects'
import { notificationCall } from 'syft-acp-core/sagas/calls'

import { EMPLOYER_SAVE_SUCCEEDED } from '../employers/action-types'
import { getEmployer$ } from '../employers/selectors'
import { EmployerEntity } from '../employers/types'

import externalEmployersTypes from './action-types'

function* notifyExternalEmployerChange() {
  yield takeLatest(
    [
      externalEmployersTypes.ENTITY_CREATE_SUCCEEDED,
      externalEmployersTypes.ENTITY_DELETE_SUCCEEDED,
      externalEmployersTypes.ENTITY_UPDATE_SUCCEEDED,
      externalEmployersTypes.ENTITY_CREATE_FAILED,
      externalEmployersTypes.ENTITY_DELETE_FAILED,
      externalEmployersTypes.ENTITY_UPDATE_FAILED,
      externalEmployersTypes.ENTITY_FETCH_FAILED,
    ],
    notificationCall,
  )
}

function* updateEmployer() {
  yield takeLatest(
    [
      externalEmployersTypes.ENTITY_CREATE_SUCCEEDED,
      externalEmployersTypes.ENTITY_DELETE_SUCCEEDED,
      externalEmployersTypes.ENTITY_UPDATE_SUCCEEDED,
    ],
    function* ({
      type,
      payload: { external_employer: externalEmployerResponse },
      request: {
        payload: { employer_id },
      },
    }: any) {
      const { external_employer, ...employer } = (yield select(state =>
        // @ts-ignore
        getEmployer$(state, employer_id),
      )) as EmployerEntity

      yield put({
        type: EMPLOYER_SAVE_SUCCEEDED,
        payload: {
          ...employer,
          ...(!type.includes('DELETE')
            ? {
                external_employer: {
                  ...external_employer,
                  ...externalEmployerResponse,
                },
              }
            : {}),
        },
      })
    },
  )
}

export default function* saga() {
  yield fork(updateEmployer)
  yield fork(notifyExternalEmployerChange)
}
