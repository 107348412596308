import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash-es'
import { Link } from 'react-router-dom'
import { Information as InformationIcon } from '@indeed/ifl-icons'
import { Tooltip } from '@indeed/ifl-components'

import * as structureTypes from 'syft-acp-core/components/EditableTable'
import { getOfferType } from '../../../core/components/Modal/ShiftFulfilmentModal/ShiftFulfilmentModal.helpers'
import { hideAllAreasOrVenues } from './ListingShiftTabs/helpers'

export const dataPropTypes = PropTypes.shape({
  id: PropTypes.number.isRequired,
  status: PropTypes.string.isRequired,
})

// How the job was offered to employees. See CMS2-273.
export const employerOffers = {
  'network,auto': '60 seconds',
  'network,random': 'All',
  all: 'All',
  network: 'Staff',
  individual: 'Individuals',
}

export const dataStructure = selectedVenueId => ({
  profile: [
    [
      'id',
      {
        header: 'ID',
      },
    ],
    [
      'status',
      {
        header: 'Status',
        editable: false,
        options: [
          { value: 'archived', label: 'Archived' },
          { value: 'live', label: 'Live' },
          { value: 'draft', label: 'Draft' },
          { value: 'requires_approver', label: 'Requires approver' },
        ],
        type: structureTypes.TYPE_ENUM,
      },
    ],
    [
      'stage',
      {
        header: 'Stage',
        editable: false,
        val: (val, data) => {
          // Note: see /syft-acp-core/components/entity-lists/ListingsList.js for more info.
          if (val === 'upcoming') {
            return data.booking_status.filled_spots === data.booking_status.total_spots
              ? 'booked'
              : 'not_booked'
          }
          return val
        },
        options: [
          { value: 'booked', label: 'Booked' },
          { value: 'not_booked', label: 'Not booked' },
          { value: 'current', label: 'Live' },
          { value: 'in_review', label: 'In review' },
          { value: 'complete', label: 'Complete' },
          { value: 'requires_approver', label: 'Requires approver' },
        ],
        type: structureTypes.TYPE_ENUM,
      },
    ],
    [
      'event_name',
      {
        header: 'Event name',
        editable: true,
      },
    ],
    [
      'event_desc',
      {
        header: 'Event description',
        editable: true,
      },
    ],
    [
      'platform_id',
      {
        header: 'Platform',
        getData: data => {
          if (data.platform_id === 1) return 'Syft'
          if (data.platform_id == null) return 'SyftForce'
          return `SyftForce (ID: ${data.platform_id})`
        },
      },
    ],
    [
      'start_time',
      {
        header: 'Start time',
        parameters: data => ({ timeZone: get(data, 'venue.timezone') }),
        type: structureTypes.TYPE_TIMESTAMP,
      },
    ],
    [
      'end_time',
      {
        header: 'End time',
        parameters: data => ({ timeZone: get(data, 'venue.timezone') }),
        type: structureTypes.TYPE_TIMESTAMP,
      },
    ],
    [
      'created_at',
      {
        header: 'Created',
        parameters: data => ({ timeZone: get(data, 'venue.timezone') }),
        type: structureTypes.TYPE_TIMESTAMP,
      },
    ],

    ['', { type: structureTypes.TYPE_SEPARATOR }],

    [
      'employer.name',
      {
        header: 'Employer',
      },
    ],
    [
      'employer.contact_name',
      {
        header: 'Contact name',
      },
    ],
    [
      'employer.contact_mobile',
      {
        header: 'Contact telephone no.',
        type: structureTypes.TYPE_PHONE_NUMBER,
      },
    ],
    [
      'employer.contact_email',
      {
        header: 'Contact email',
      },
    ],
    [
      'employer.syft_manger',
      {
        header: 'Manager name',
      },
    ],
    [
      'offer_to',
      {
        header: 'Employer offer',
        calc: ({ offer_to, platform_id }) => {
          return getOfferType(employerOffers, offer_to, platform_id)
        },
      },
    ],
    [
      'fee_percent',
      {
        header: 'Fee',
        type: structureTypes.TYPE_PERCENT,
        editable: true,
      },
    ],
    [
      'rating.average',
      {
        header: 'Avg. rating by workers',
        type: structureTypes.TYPE_RATING,
      },
    ],

    ['', { type: structureTypes.TYPE_SEPARATOR }],

    [
      'venues',
      {
        header: 'Venues',
        type: structureTypes.TYPE_VENUE_TABS,
        hide: hideAllAreasOrVenues('venue'),
      },
    ],
    [
      'city_name',
      {
        header: 'City',
        getData: data => {
          if (hideAllAreasOrVenues('venue')(data) || !selectedVenueId) {
            return data?.city_name
          }
          return data.venues?.find(venue => venue.id === selectedVenueId)?.address?.city
        },
      },
    ],
    [
      'venue.name',
      {
        header: 'Venue',
        type: structureTypes.TYPE_LINK,
        getData: data => `${data.venue.name} (${data.venue.id})`,
        parameters: {
          to: data => `/entity/employers/view/${data.employer.id}/venues/view/${data.venue.id}`,
        },
        editable: false,
        hideIfEmpty: true,
        hide: data => !hideAllAreasOrVenues('venue')(data),
      },
    ],
    [
      'venue.address',
      {
        header: 'Address',
        getData: data => {
          if (hideAllAreasOrVenues('venue')(data) || !selectedVenueId) {
            return data?.venue?.address
          }
          const selectedVenue = data.venues?.find(venue => venue.id === selectedVenueId)
          return selectedVenue?.address
        },
        type: structureTypes.TYPE_VENUE_ADDRESS,
      },
    ],
    [
      'venue.maximum_arrival_time',
      {
        header: 'Max. arrival minutes',
        getData: data => {
          if (hideAllAreasOrVenues('venue')(data) || !selectedVenueId) {
            return data?.venue?.maximum_arrival_time
          }
          const selectedVenue = data.venues?.find(venue => venue.id === selectedVenueId)
          return selectedVenue?.maximum_arrival_time
        },
        editable: false,
      },
    ],
    [
      'venue.access_instructions',
      {
        header: 'Access instructions',
        getData: data => {
          if (hideAllAreasOrVenues('venue')(data) || !selectedVenueId) {
            return data?.venue?.access_instructions
          }
          const selectedVenue = data.venues?.find(venue => venue.id === selectedVenueId)
          return selectedVenue?.access_instructions
        },
        editable: false,
      },
    ],
    [
      'venue.work_location_contact.name',
      {
        header: 'Contact name',
        editable: false,
        getData: data => {
          if (hideAllAreasOrVenues('venue')(data) || !selectedVenueId) {
            return data?.venue?.work_location_contact?.name
          }
          const selectedVenue = data.venues?.find(venue => venue.id === selectedVenueId)
          return selectedVenue?.work_location_contact?.name
        },
      },
    ],
    [
      'venue.work_location_contact.mobile',
      {
        header: 'Contact mobile',
        editable: false,
        type: structureTypes.TYPE_PHONE_NUMBER,
        getData: data => {
          if (hideAllAreasOrVenues('venue')(data) || !selectedVenueId) {
            return data?.venue?.work_location_contact?.mobile
          }
          const selectedVenue = data.venues?.find(venue => venue.id === selectedVenueId)
          return selectedVenue?.work_location_contact?.mobile
        },
      },
    ],
    [
      'venue.work_location_contact.email',
      {
        header: 'Contact email',
        editable: false,
        getData: data => {
          if (hideAllAreasOrVenues('venue')(data) || !selectedVenueId) {
            return data?.venue?.work_location_contact?.email
          }
          const selectedVenue = data.venues?.find(venue => venue.id === selectedVenueId)
          return selectedVenue?.work_location_contact?.email
        },
      },
    ],
    [
      'venue.work_location_note.body',
      {
        header: 'Venue notes',
        getData: data => {
          if (hideAllAreasOrVenues('venue')(data) || !selectedVenueId) {
            return data?.venue?.work_location_note?.body
          }
          const selectedVenue = data.venues?.find(venue => venue.id === selectedVenueId)
          return selectedVenue?.work_location_note?.body
        },
        editable: false,
      },
    ],
    [
      'venue',
      {
        header: '',
        getData: data => (
          <Link
            to={`/entity/employers/view/${data.employer.id}/venues/view/${
              selectedVenueId || data?.venues?.[0]?.id
            }`}
          >
            Link to Venue
          </Link>
        ),
        editable: false,
        hide: data => hideAllAreasOrVenues('venue')(data),
      },
    ],
  ],
})

export const mainInformationStructure = (
  timeDependenceTimes,
  enableListingDetailRoleChanges,
  roleSelectedID,
) => [
  [
    'start_time',
    {
      header: 'Date',
      parameters: data => ({ timeZone: get(data, 'venue.timezone') }),
      type: structureTypes.TYPE_TIMESTAMP,
    },
  ],
  [
    'id',
    {
      header: 'Shift ID',
    },
  ],
  [
    'external_booking_uuids',
    {
      header: 'External booking UUIDs',
      type: structureTypes.TYPE_EXTERNAL_UUIDS,
      editable: true,
    },
  ],
  [
    'job.role.title',
    {
      header: 'Role',
      hide: data => {
        return !!data.roles && data?.roles?.length > 1 && enableListingDetailRoleChanges
      },
    },
  ],
  [
    'roles',
    {
      header: 'Role',
      type: structureTypes.TYPE_ROLE_TABS,
      editable: true,
      hide: data => {
        return !data.roles || data?.roles?.length < 2 || !enableListingDetailRoleChanges
      },
    },
  ],
  [
    'job.desc',
    {
      header: 'Job Description',
      getData: data => {
        if (!!data.roles && data?.roles?.length > 1 && enableListingDetailRoleChanges && !!roleSelectedID) {
          return data.roles?.find(role => role.id === roleSelectedID)?.job_desc
        }
        return data?.job?.desc
      },
    },
  ],
  [
    'job.uniform.picture',
    {
      header: 'Uniform Picture',
      type: structureTypes.TYPE_IMAGE,
      editable: true,
      hide: data => {
        return (
          !!data.roles &&
          data?.roles?.length > 1 &&
          enableListingDetailRoleChanges &&
          roleSelectedID &&
          roleSelectedID !== data?.job?.role.id
        )
      },
    },
  ],

  [
    'job.uniform.desc',
    {
      header: 'Uniform Description',
      editable: true,
      hide: data => {
        return (
          !!data.roles &&
          data?.roles?.length > 1 &&
          enableListingDetailRoleChanges &&
          roleSelectedID &&
          roleSelectedID !== data?.job?.role.id
        )
      },
    },
  ],
  [
    'job.uniform.desc',
    {
      header: 'Uniform Description',
      getData: data => {
        return data?.roles?.find(role => role.id === roleSelectedID)?.uniform?.desc
      },
      hide: data => {
        const lessThanOneRole = data?.roles?.length < 2
        const moreThanOneRole = !!data.roles && data?.roles?.length > 1
        const firstRoleSelected = (roleSelectedID && roleSelectedID === data?.job?.role.id) || !roleSelectedID
        return (moreThanOneRole && enableListingDetailRoleChanges && firstRoleSelected) || lessThanOneRole
      },
    },
  ],
  [
    'start_time',
    {
      header: 'Start time',
      parameters: data => ({ timeZone: get(data, 'venue.timezone') }),
      type: structureTypes.TYPE_TIMESTAMP,
      editable: true,
    },
  ],
  [
    'end_time',
    {
      header: 'End time',
      editable: true,
      parameters: data => ({ timeZone: get(data, 'venue.timezone') }),
      type: structureTypes.TYPE_TIMESTAMP,
    },
  ],
  [
    'category',
    {
      header: 'Shift type',
      editable: true,
      options: [
        { value: 'overbook', label: 'Overbook' },
        { value: 'check_in', label: 'Check in' },
        { value: 'check_in_overbook', label: 'CI/OB' },
        { value: 'no_show', label: 'No show' },
        { value: 'induction', label: 'Induction' },
        { value: null, label: 'None' },
      ],
      type: structureTypes.TYPE_ENUM,
    },
  ],
  [
    'job.pay_rate',
    {
      header: (
        <>
          Client rate{' '}
          <Tooltip
            label="WARNING: Editing this field will change ALL shifts for ALL workers on this job. If you want to edit a single booking's rate, use the Granular Shift Edit feature from the Client Portal."
            placement="bottom"
            arrow
          >
            <InformationIcon size="sm" sx={{ verticalAlign: 'top' }} color="grey" />
          </Tooltip>
        </>
      ),
      type: structureTypes.TYPE_RATE,
      editable: true,
    },
  ],
  [
    'offer_rate',
    {
      header: 'Offer rate',
      type: structureTypes.TYPE_RATE,
      disableOn: ['job.bookable_individually', false],
      showZero: false,
      editable: true,
    },
  ],
  [
    'job.paid_break',
    {
      header: 'Paid break',
      getData: data => (data?.job?.paid_break ? 'True' : 'False'),
    },
  ],
  ...timeDependenceTimes.map((item, idx) => {
    const { start, end, label } = item.time_dependent_rate
    const suffix = start && end ? `${label} (${start} - ${end})` : label
    const header = idx !== 0 ? `Dependent rate ${idx + 1}` : 'Dependent rate'
    return [
      `job_time_dependent_rates[${idx}].pay_rate`,
      {
        header,
        type: structureTypes.TYPE_RATE,
        suffix,
        showZero: false,
        editable: false,
      },
    ]
  }),
]
