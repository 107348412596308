import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { ControlLabel, HelpBlock, Row, Col, FormGroup } from 'react-bootstrap'
import { get } from 'lodash-es'
import FormattedTimeRange from 'syft-acp-core/components/FormattedTimeRange'

import { fetchNoShowReasons } from 'syft-acp-core/actions/no-show-reasons'
import { filterSelect } from 'syft-acp-util/components/FilterForm/FilterSelect'
import { formatFullName } from 'syft-acp-util/formatting'
import { getModal$ } from 'syft-acp-core/store/modals/selectors'
import { Modal } from 'syft-acp-core/components/Modal'
import { needsNoShowReasonsUpdate$ } from 'syft-acp-core/reducers/no-show-reasons'
import { showModal, hideModal } from 'syft-acp-core/store/modals/actions'
import DataTextarea from 'syft-acp-core/components/EditableTable/DataTextarea'
import Table from 'syft-acp-util/components/Table'
import UserLine from 'syft-acp-core/components/UserAvatar/UserLine'

import './AddNoShowReasonModal.css'

// Capitalize the first letter of a string.
const upcFirst = str => `${str.charAt(0).toUpperCase()}${str.slice(1)}`

// Displays some basic information about the booking.
export const ShortBookingInfo = ({ shiftBooking, timeZone }) => (
  <Table bordered colBorders className="tiny-headers booking-info">
    <tbody>
      <tr>
        <th>Booking ID</th>
        <td>{shiftBooking.id}</td>
      </tr>
      <tr className="worker-line">
        <th>Worker</th>
        <td>
          <UserLine
            avatarUUID={get(shiftBooking, 'worker.profile_picture.uuid', '')}
            name={shiftBooking.worker ? formatFullName(shiftBooking.worker) : shiftBooking.full_name}
          />
        </td>
      </tr>
      {timeZone && (
        <tr>
          <th>Shift</th>
          <td>
            <FormattedTimeRange
              value={[
                get(
                  shiftBooking,
                  'clock_in.time',
                  get(shiftBooking, 'clock_in_time', get(shiftBooking, 'shift.start_time')),
                ),
                get(
                  shiftBooking,
                  'clock_out.time',
                  get(shiftBooking, 'clock_out_time', get(shiftBooking, 'shift.end_time')),
                ),
              ]}
              format={{ month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit', timeZone }}
            />
          </td>
        </tr>
      )}
    </tbody>
  </Table>
)

class AddNoShowReasonModal extends React.PureComponent {
  static propTypes = {
    shiftBooking: PropTypes.object,
  }

  constructor(props) {
    super(props)
    this.initialState = {
      noShowReason: null,
      comments: '',
    }
    this.state = this.initialState
  }

  componentDidMount() {
    if (needsNoShowReasonsUpdate$()) {
      this.props.dispatch(fetchNoShowReasons())
    }
  }

  resetForm = () => {
    this.setState(this.initialState)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // If we are becoming visible, while not being visible, reset the form.
    if (this.props.item.isShown !== nextProps.item.isShown && nextProps.item.isShown === true) {
      this.setState(this.initialState)
    }
  }

  onConfirmHandler = async () => {
    const isValid = this.validate()

    if (!isValid) {
      return
    }
    return this.closeModal()
  }

  // Note: 'onlyTurnOff' will remove the validation error state if the form is valid.
  // If set to true, it will not set the error state to true, but leave it on true if it already is.
  validate = (onlyTurnOff = false, newState = {}) => {
    const currentData = { ...this.state, ...newState }
    const { noShowReason } = currentData
    let valid

    if (!onlyTurnOff || (onlyTurnOff && currentData.valid !== true)) {
      // No worker selected.
      if (!noShowReason) {
        valid = false
        this.setState({ ...currentData, valid, msg: 'Select a reason' })
        return valid
      }
    }

    valid = true
    this.setState({ ...currentData, valid })

    return valid
  }

  closeModal = () => {
    if (this.props.item.onClose) {
      this.props.item.onClose({ noShowReasonID: this.state.noShowReason, comments: this.state.comments })
    }
    this.props.dispatch(hideModal(this.props.item.modalName))
  }

  openModal = () => this.props.dispatch(showModal(this.props.item.modalName))

  setNoShowReason = noShowReason => {
    this.validate(true, { noShowReason })
  }

  setComments = comments => {
    this.validate(true, { comments })
  }

  render() {
    const noShowReasons = get(this.props, 'noShowReasons', {})
    const shiftBooking = get(this.props.item, 'options.shiftBooking', false)
    const { noShowReason, comments } = this.state

    const reasonOptions = Object.values(noShowReasons).map(r => ({ id: r.id, label: upcFirst(r.desc) }))
    const FilterSelectReason = filterSelect(
      reasonOptions,
      'Reason',
      false,
      false,
      false,
      this.setNoShowReason,
    )

    return (
      <Modal
        header="Set no show reason for worker"
        isShown={this.props.item.isShown}
        onClose={this.closeModal}
        onConfirm={this.onConfirmHandler}
        confirmationText="Set reason"
      >
        <div className="missing-time-modal">
          {shiftBooking && <ShortBookingInfo shiftBooking={shiftBooking} />}
          <FormGroup className="form-item">
            <Row>
              <Col md={12}>
                <ControlLabel>No show reason</ControlLabel>
                <FilterSelectReason
                  name="reason"
                  options={reasonOptions}
                  value={noShowReason}
                  onChange={this.setNoShowReason}
                />
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <ControlLabel>Comments</ControlLabel>
                <DataTextarea name="comments" value={comments} onChange={this.setComments} />
              </Col>
            </Row>
          </FormGroup>
          <HelpBlock className="bottom-help">Select a reason and press "set".</HelpBlock>
        </div>
      </Modal>
    )
  }
}

export default connect((state, ownProps) => ({
  item: getModal$(state.modals)('addNoShowReasonModal'),
  noShowReasons: state.noShowReasons.entityMap,
  shiftBooking: ownProps.shiftBooking,
}))(AddNoShowReasonModal)
