import * as types from './action-types'

export const fetchEmployerVenuesList = (data: Record<string, any> = {}, toCSV = false) => ({
  type: types.EMPLOYER_VENUES_FETCH_BEGIN,
  toCSV,
  options: data.options,
})

export const fetchEmployerFilteredVenuesList = (employerID: number) => {
  return { type: types.EMPLOYER_VENUES_FILTERED_FETCH_BEGIN, employerID }
}

export const saveEmployerVenue = (employerID: number, id: number, data: Record<string, any>) => ({
  type: types.EMPLOYER_VENUE_SAVE_BEGIN,
  employerID,
  id,
  data,
})

export const deleteEmployerVenue = (employerID: number, id: number) => ({
  type: types.EMPLOYER_VENUE_DELETE_BEGIN,
  employerID,
  id,
})

export const fetchEmployerVenue = (employerID: number, venueID: number) => ({
  type: types.EMPLOYER_VENUE_FETCH_BEGIN,
  employerID,
  venueID,
})

export const fetchVenueAreas = (venueID: number, options: Record<string, any>) => ({
  type: types.EMPLOYER_VENUE_AREAS_FETCH_BEGIN,
  venueID,
  options,
})

export const saveEmployerVenueArea = (
  employerID: number,
  venueID: number,
  id: number,
  data: Record<string, any>,
) => ({
  type: types.EMPLOYER_VENUE_AREA_SAVE_BEGIN,
  employerID,
  venueID,
  id,
  data,
})

export const deleteEmployerVenueArea = (employerID: number, venueID: number, id: number) => ({
  type: types.EMPLOYER_VENUE_AREA_DELETE_BEGIN,
  employerID,
  venueID,
  id,
})
