import { get, isArray, keyBy, map, range } from 'lodash-es'
import { emptySet } from 'syft-acp-core/reducers/generators/constants'
import { entitySetName } from 'syft-acp-core/reducers/generators/utils'

export const entitiesFetchSucceed = (state, action, idKey, options) => {
  const lastUpdate = Number(new Date())
  const setKey = entitySetName(action.options, options, action)

  const currentSet = state.entitySets[setKey] || {}

  // In some cases, we don't want to update the items we fetched.
  // Such as when we've just fetched a CSV file, which contains far more items than we
  // normally display in an entity list.
  // In these cases, we want to just reset the state to turn off loading.
  if (action.noPayloadUpdate) {
    return {
      ...state,
      lastMessage: '',
      lastStatus: null,
      lastBody: null,
      isLoadingData: false,
      entitySets: {
        ...state.entitySets,
        [setKey]: {
          ...emptySet,
          ...currentSet,
          isLoadingData: false,
        },
      },
    }
  }

  // If needed, we can preprocess the payload before saving it.
  const payload = options.payloadProcess ? options.payloadProcess(action.payload, action) : action.payload
  // As with the entityMap, either we map the payload by an idKey
  // or by taking an array directly from the payload.
  const idRange = options.keyByPayload
    ? range(get(payload, options.keyByPayload).length)
    : map(isArray(payload) ? payload : [payload], idKey)

  // Reset cached values in entityDetail on fetch
  const shouldResetEntityDetail = options.resetEntityDetailOnFetch
    ? {
        entityDetail: {},
      }
    : {}

  return {
    ...state,
    lastMessage: '',
    lastStatus: null,
    lastBody: null,
    isLoadingData: false,
    entityUpdateTimes: {
      ...state.entityUpdateTimes,
      ...idRange.reduce((acc, n) => ({ ...acc, [n]: lastUpdate }), {}),
    },
    entitySets: {
      ...state.entitySets,
      [setKey]: {
        ...emptySet,
        ...currentSet,
        // Note: if we're using a localEntityMap, it means we are saving all the actual data
        // inside the entityMap. Normally, we only save IDs in here.
        entityMap: options.localEntityMap
          ? {
              // Add the contents of the old entityMap.
              ...get(currentSet, 'entityMap'),
              // And add either the contents of our payload by a specific key,
              // or add an array made using 'idKey' as key. (Most cases.)
              ...(options.keyByPayload ? get(payload, options.keyByPayload) : keyBy(payload, idKey)),
            }
          : get(currentSet, 'entityMap'),
        isLoadingData: false,
        ids: idRange,
        totalPages: action.totalPages,
        totalWorkers: action.totalWorkers,
        totalBookings: action.totalBookings,
        totalShifts: action.totalShifts,
        totalVacancies: action.totalVacancies,
        total: action.total,
        activePage: action.activePage,
        perPage: action.perPage,
        nextPage: action.nextPage,
        page: action.page,
      },
    },
    entityMap: {
      ...state.entityMap,
      ...(options.keyByPayload ? get(payload, options.keyByPayload) : keyBy(payload, idKey)),
    },
    ...shouldResetEntityDetail,
  }
}
