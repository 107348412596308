// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React, { PureComponent, useMemo } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import ReactTooltip from 'react-tooltip'

import { Button, ButtonLink, ButtonGroup } from 'syft-acp-atoms/Button'
import { showModal } from 'syft-acp-core/store/modals/actions'
import { store } from 'syft-acp-core/store'
import * as employersActions from 'syft-acp-core/store/employers/actions'
import * as employerTokenActions from 'syft-acp-core/actions/employer-tokens'
import EmployerDownloadInvoicesModal from 'syft-acp-core/components/Modal/EmployerDownloadInvoicesModal'
import { getTokenOpenLogin } from './helpers'
import { loginAsEmployerBlacklistedIds } from './constants'
import { useLocation } from 'react-router-dom'
import { useFlexFeatureFlagsContext, useFlexFlagIsOn } from '@indeed/flex-feature-flags'

export class EmployerDetailControlsLeft extends PureComponent {
  static propTypes = {
    data: PropTypes.object,
    token: PropTypes.object,
    actions: PropTypes.object,
    userData: PropTypes.object,
    id: PropTypes.number,
  }

  static defaultProps = {
    id: null,
    token: null,
    actions: null,
    data: null,
    userData: null,
  }

  /** Checks whether our token to login as employer is still valid. If not, request a new one.
   * @param {Object} options
   * @param {boolean} options.replace - replace the current browsing context (defaults to false)
   * @param {boolean} options.flexAgent - navigates to flex agent root if true
   * @param {string} options.flexAgentConversationId - navigates to flex agent conversation if present, takes precedence over flexAgent
   **/
  checkToken = (options = {}) => {
    const { actions, data, token, userData, id } = this.props
    getTokenOpenLogin({
      actions,
      data,
      token,
      userData,
      employerId: id,
      flexAgent: options.flexAgent,
      flexAgentPath: options.flexAgentPath,
      flexAgentConversationId: options.flexAgentConversationId,
      ...(options.replace && { target: '_self' }),
    })
  }

  invoiceAction = () => {
    store.dispatch(showModal('employerDownloadInvoicesModal'))
  }

  getLoginAsEmployerConfig = () => {
    const { data } = this.props
    const employerID = data?.id
    const isLoginAsEmployerBlacklisted = loginAsEmployerBlacklistedIds.includes(employerID)

    return {
      employerID,
      isLoginAsEmployerBlacklisted,
      isVerified: data && !!data.approved_at,
    }
  }

  componentDidMount() {
    this.handleFlexAgentConversationRedirect()
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.data && this.props.data) {
      this.handleFlexAgentConversationRedirect()
    }
  }

  handleFlexAgentConversationRedirect() {
    const { searchParams, flexAgentPath } = this.props
    const { isLoginAsEmployerBlacklisted, isVerified } = this.getLoginAsEmployerConfig()
    const flexAgentConversationId = searchParams.get('flex_agent_conversation')
    const canLoginToFlexAgent = !isLoginAsEmployerBlacklisted && isVerified

    if (canLoginToFlexAgent && flexAgentConversationId) {
      this.checkToken({
        flexAgent: true,
        flexAgentPath,
        flexAgentConversationId,
        replace: true,
      })
    }
  }

  render() {
    const { data, flexAgentPath } = this.props
    const { employerID, isLoginAsEmployerBlacklisted, isVerified } = this.getLoginAsEmployerConfig()
    const hasOneLineInvoicing = data && data.one_line_invoicing

    return (
      <span>
        <ReactTooltip effect="solid" place="bottom" />
        <EmployerDownloadInvoicesModal employerID={data && data.id} />{' '}
        <ButtonGroup>
          <ButtonLink to={`/shifts?employer_id=${employerID}`} disabled={!employerID}>
            View shifts
          </ButtonLink>

          {!isLoginAsEmployerBlacklisted && (
            <Button onClick={this.checkToken} external target="_blank">
              <span
                {...(!isVerified ? { 'data-tip': 'Employer must be verified before you can login' } : {})}
              >
                Login as employer
              </span>
            </Button>
          )}
        </ButtonGroup>{' '}
        <ButtonGroup>
          <Button onClick={this.invoiceAction} disabled={!hasOneLineInvoicing}>
            <span
              {...(!hasOneLineInvoicing
                ? { 'data-tip': 'Can only download invoices if one line invoicing is active' }
                : {})}
            >
              Download invoices
            </span>
          </Button>
        </ButtonGroup>{' '}
        {!isLoginAsEmployerBlacklisted && (
          <ButtonGroup>
            <Button
              onClick={() => this.checkToken({ flexAgent: true, flexAgentPath })}
              external
              target="_blank"
            >
              <span
                {...(!isVerified ? { 'data-tip': 'Employer must be verified before you can login' } : {})}
              >
                Flex Agent
              </span>
            </Button>
          </ButtonGroup>
        )}
      </span>
    )
  }
}

const WithRouterEmployerDetailControls = props => {
  const location = useLocation()
  const { isLoading } = useFlexFeatureFlagsContext()
  const isFlexAgentPanelEnabled = useFlexFlagIsOn('wfm_flex_agent_ui_panel')
  const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search])

  // Don't render anything while feature flags are loading
  if (isLoading) {
    return null
  }

  return (
    <EmployerDetailControlsLeft
      {...props}
      flexAgentPath={isFlexAgentPanelEnabled ? 'o/roster' : undefined}
      searchParams={searchParams}
    />
  )
}
export const EmployerDetailControlsLeftConnected = connect(
  (state, ownProps) => ({
    data: state.employers.entityDetail[ownProps.employerID],
    token: state.employerToken?.employers[ownProps.employerID],
    userData: state.auth?.userData,
    isPristine: true,
  }),
  dispatch => ({
    actions: bindActionCreators({ ...employerTokenActions, ...employersActions }, dispatch),
  }),
)(WithRouterEmployerDetailControls)
