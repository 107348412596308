// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { InputGroup } from 'react-bootstrap'
import { find, get, isFunction } from 'lodash-es'

import FormattedDateTime, { fullLocalDateTimeFormat } from 'syft-acp-core/components/FormattedDateTime'
import { IconClock } from 'syft-acp-core/components/IconClock'
import DatePicker from 'syft-acp-atoms/DatePicker'
import {
  formatDatetime8601,
  assumeTimezone,
  formatRelative,
  timezoneAbbrOffset,
  timezoneAbbrOffsetStr,
} from 'syft-acp-util/formatting'

/**
 * Datetime input field.
 *
 * This datetime field uses 'datetime-local' to display the time, meaning it needs to do
 * some trickery to get all dates to show up in the timezone from our settings.
 *
 * A datetime-local is always displayed in the USER'S timezone, even if the timezone is not visible.
 * So if you feed it '2018-01-01T13:30:00+0100', it might display '2018-01-01 14:30:00' if you are in +0200.
 * For this reason, we 1) remove the timezone completely from the datetime-local input,
 * and 2) stick the correct timezone on the resulting value that comes from the field.
 *
 * In essence, this means the timezone is incorrect for a little while - the onChange() value
 * assumes that whatever is displayed is in the user's timezone, whereas we're actually faking it,
 * putting e.g. a BST timestamp on a value that is actually valid for EST.
 *
 * Until we get a 'timezone' setting for the datetime-local field, we will need to keep this hack.
 */

const DataDatetime = ({ value, editable, disabled, onChange, parameters, data, showTimezone = true }) => {
  const { timeZone } = (isFunction(parameters) ? parameters(data) : parameters) || {}
  const customFormat = parameters.customFormat
  // Here we remove the timezone from the datetime:
  // this makes it impossible for the datetime-local to offset the time into
  // the user's timezone. So e.g. a '13:30:00+0100' time actually shows up as that value.
  // Even though internally it will actually be '13:30:00+0200' (or whatever the user's timezone is).
  const valueOffset = value ? formatDatetime8601(value, timeZone) : ''
  const tzData = value ? timezoneAbbrOffset(value, timeZone) : ''

  // Assume that the timezone of what was output is our system timezone.
  // See the comment below for more details.
  const handleChange = useCallback(
    (ts, offset) => {
      const time = assumeTimezone(ts, offset)

      // Double check to ensure we're not setting an invalid date.
      const isInvalid = String(new Date(time)) === 'Invalid Date'
      if (isInvalid) return

      onChange(time)
    },
    [onChange],
  )
  const handleDateTimeChange = useCallback(
    (name, val) => handleChange(val, tzData.offset),
    [handleChange, tzData],
  )

  if (editable) {
    return (
      <InputGroup className="boxed-input">
        {/* System timezone, e.g. 'BST +01:00'. */}
        {value && showTimezone && (
          <InputGroup.Addon>
            <FormattedDateTime value={value} format={{ timeStyle: 'long', timeZone }} toParts>
              {valDateTimeParts =>
                get(
                  find(valDateTimeParts, part => part.type === 'timeZoneName'),
                  'value',
                ) || timezoneAbbrOffsetStr(value)
              }
            </FormattedDateTime>
          </InputGroup.Addon>
        )}
        {/* The datetime-local field containing our timezone-less datetime value. */}
        <DatePicker
          showTime
          disabled={disabled}
          value={valueOffset}
          onChange={handleDateTimeChange}
          customFormat={customFormat}
        />
      </InputGroup>
    )
  } else {
    return (
      <div className="plain-text">
        {/* Value in format of provided timezone, e.g. '23/04/2018, 12:30:00 EST'. */}
        {value ? <FormattedDateTime value={value} format={{ ...fullLocalDateTimeFormat, timeZone }} /> : '—'}
        {/* Clock icon and relative time, e.g. 'in 2 hours'. */}
        {value ? (
          <span className="secondary">
            <IconClock />
            {formatRelative(value)}
          </span>
        ) : null}
      </div>
    )
  }
}

DataDatetime.propTypes = {
  value: PropTypes.string,
  editable: PropTypes.bool,
  disabled: PropTypes.bool,
  showTimezone: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
}

DataDatetime.defaultProps = {
  editable: false,
  disabled: false,
  value: null,
}

export default DataDatetime
