import React from 'react'

import { colPropTypes, colDefaultProps } from '../AcpTable/cellComponents/dataProps'

/**
 * Generates a "placeholder" React component. This type of component
 * is only used for configuration and doesn't render to anything.
 */
const placeholderFactory = (
  identityName,
  propTypes = {},
  defaultProps = {},
  nestedTypes = [],
  nestedTypeGroups = [],
  groupName = null
) =>
  (class extends React.PureComponent {
    static propTypes = propTypes

    static defaultProps = defaultProps

    /** Name of the component - used to preserve the name through uglification. */
    static identityName = identityName

    /** Some placeholders have a base type that groups them together. */
    static groupName = groupName

    /** List of possible nested node types. */
    static nestedTypes = nestedTypes

    /** List of possible nested node type groups. */
    static nestedTypeGroups = nestedTypeGroups

    render() {
      return null
    }
  })

export default placeholderFactory

/**
 * Generates a placeholder for a table column definition component.
 */
export const colPlaceholderFactory = (identityName, nestedTypes, nestedTypeGroups, editable = false) =>
  placeholderFactory(
    identityName,
    colPropTypes,
    colDefaultProps,
    nestedTypes,
    nestedTypeGroups,
    !editable ? 'AcpCol' : 'AcpEditableCol'
  )

/**
 * Generates a placeholder for an editable table column definition component.
 */
export const colEditablePlaceholderFactory = (identityName, nestedTypes, nestedTypeGroups) =>
  colPlaceholderFactory(identityName, nestedTypes, nestedTypeGroups, true)
