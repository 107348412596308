export type Amount = {
  amount: number
  currency: string
}

export type EmployerRateCardEntity = {
  id: number
  work_location_id: number | null
  venue_category_id: number
  role_id: number
  skill_id: number | null
  min_payable_rate: Amount
  max_payable_rate: Amount
  default_payable_rate: Amount
  invoice_rate: Amount
  fee_percent: string | null
  disabled_at: string | null
  effective_date: string
  margins_percent: number | null
  burdens_percent: number | null
  time_dependent_rates: TimeDependentRate[]
  shift_rate_type_id?: number
  awr_parity_rate: Amount
  employer_id: number | null
  awr_parity_invoice_rate: Amount
}

export type TimeDependentRate = {
  bank_holiday_region_id: number | null
  default_payable_rate: Amount
  end: string
  id: number
  invoice_rate: Amount | null
  label: string
  max_payable_rate: Amount
  min_payable_rate: Amount
  rate_card_id: number
  start: string
  week_days: string[]
  margins_percent: number | null
  awr_parity_rate: Amount | null
  awr_parity_invoice_rate: Amount | null
}

export type FetchListOptions = {
  options: Partial<{
    [key: string]: any
  }>
}

export type ListEmployerRateCardsInput = {
  options: Record<string, any>
  toCSV: boolean
}

export type FetchEmployerRateCardInput = { rateCardID: number }

export type SaveEmployerRateCardInput = {
  id: number
  data: Record<string, any>
  employerID: number
}

export type BulkPayload = {
  ids: number[]
}

export enum ModalNames {
  RateCardsReplace = 'rateCardReplacement',
  RateCardsConfirm = 'rateCardsConfirm',
}

export type EmployerRateCardListCheckbox = {
  id: number
}
