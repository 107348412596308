// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash-es'
import Table from 'syft-acp-util/components/Table'
import EditableTableRow from './EditableTableRow'
import FieldComponent, { FieldComponentWrapper } from './FieldComponent'
import { TYPE_TXT } from './types'

import './EditableTable.css'

/**
 * Passes along the update event to the specified callback.
 *
 * @param {String} fieldName Key string identifying the edited field
 * @param {Function} callback Function that will handle the update
 * @param {Array} triggers Callback triggers to run after editing
 */
const editField = (fieldName, callback, triggers) => value => callback(fieldName, value, triggers)

/**
 * Parses the table's structure and determines which components to display.
 *
 * @param {Object} structure Structure with which to display the data
 * @param {Object} data Record data (either from database or from user input)
 * @param {Object|Array} defaultData Default Record data
 * @param {Function} update Generic update handler to be bound to all form elements
 * @param {Boolean} isSaved Whether this record has been saved yet
 * @param {Object} context Optional data passed on from the containing component
 */
const parseStructure = (structure, data, defaultData, update, isSaved, context) => {
  // Collect update triggers from the structure.
  const triggers = structure
    .filter(item => Object.keys(item[1]).indexOf('triggers') !== -1)
    .reduce(
      (acc, item) => [
        ...acc,
        // Add in the trigger, and inject the item name.
        ...item[1].triggers.reduce((tAcc, trigger) => [...tAcc, { ...trigger, to: item[0] }], []),
      ],
      [],
    )
  return structure.map(field => {
    const value = field[1].calc ? field[1].calc(data) : get(data, field[0])
    const options = {
      defaultValue: get(defaultData, field[0]),
      ...field[1],
      // Either retrieve the data, or derive it from the passed calc() function.
      value,
      type: field[1].type || TYPE_TXT,
      val: field[1].val,
      prefix: field[1].prefix,
      suffix: field[1].suffix,
      name: field[0],
      callback: editField(field[0], update, triggers),
      callbackField: fieldName => editField(fieldName, update, triggers),
      context,
      triggers: field[1].triggers,
      isSaved,
      data,
    }

    // Produce whichever component we need for this data type.
    const node = (
      <FieldComponentWrapper {...options}>
        <FieldComponent {...options} />
      </FieldComponentWrapper>
    )

    return { ...options, node }
  })
}

/**
 * The EditableTable component is used to modify records from the API, such as a worker
 * or an employer. It takes a structure definition and displays appropriate form elements
 * for the user to edit. Initial data can be provided if the user is editing rather than
 * creating a new record.
 *
 * The "structure" prop is an array of structure definitions in the format [key, { options }]:
 *
 *    const exampleStructure = [
 *      ['id', { header: 'ID' }],
 *      ['company_name', { header: 'Company name', editable: true }],
 *      ['created_on', { header: 'Created on', type: 'timestamp' }],
 *    ];
 *
 * The following options are available:
 *
 *    {string}   header     Human-friendly title of the form item
 *    {boolean}  editable   Whether this field is editable or display-only
 *    {function} calc       Function that returns the value of a (read-only) field
 *    {string}   type       Data type; must be one of:
 *       * timestamp           Formatted time/date string (or date input field if editable)
 *       * rating              Five star based rating system
 *       * profile             Single photo (file) uploader
 *       * textarea            Multi-line text input
 *       * rate                Currency text input (e.g. £9.50)
 *       * coords              Location coordinates
 *       * checkbox            Boolean true or false
 *       * txt                 Single line text input
 *
 * The key names need to correspond with the key names in the "data" prop if it is passed.
 * The 'calc' function can be used to create dynamic fields, for read only data.
 * For example: ['full_name', { calc: data => `${data.first_name} ${data.last_name}` }].
 * The calc() function must return the data type expected by the type field.
 *
 * Complex objects are supported as well. For example, if we have the following data:
 *
 *    address: { 'line_1': 'something', 'line_2': 'something else' }
 *
 * The 'structure' could include two text input fields, one for 'address.line_1' and one
 * for the other.
 */
const EditableTable = ({
  noRowBorders,
  structure,
  data,
  update,
  context,
  isSaved,
  fieldData,
  defaultData,
  dataPrefix,
  noBottomMargin,
}) => {
  const updateWithPrefix = useCallback(
    (...args) => {
      update(...args, dataPrefix)
    },
    [update, dataPrefix],
  )
  const parsedRows = useMemo(
    () => parseStructure(structure, data, defaultData, updateWithPrefix, isSaved, context),
    [structure, data, updateWithPrefix, isSaved, context, defaultData],
  )

  return (
    <Table
      noRightBorder
      noRowBorders={noRowBorders}
      noBottomMargin={noBottomMargin}
      className="editable-table"
    >
      <tbody>
        {
          // Run through our provided structure and determine the components to display.
          parsedRows.map((row, n) => (
            <EditableTableRow key={String(n)} row={row} fieldData={fieldData} />
          ))
        }
      </tbody>
    </Table>
  )
}

EditableTable.propTypes = {
  /** Data displayed in the form. @type {Object} */
  data: PropTypes.object.isRequired,
  /** See documentation above for how to set a structure. @type {Object} */
  structure: PropTypes.array.isRequired,
  /** Callback for field edits. @type {Function} */
  update: PropTypes.func,
  /** Whether this editable table is on a page that has been saved at least once. @type {Boolean} */
  isSaved: PropTypes.bool,
  /** Default data. */
  defaultData: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  /** Data to pass on to the field edit component. */
  fieldData: PropTypes.object,
  /** if should hide borders; true by default for backward compat */
  noRowBorders: PropTypes.bool,
  context: PropTypes.object,
  dataPrefix: PropTypes.string,
  noBottomMargin: PropTypes.bool,
}

EditableTable.defaultProps = {
  update: () => {},
  isSaved: false,
  fieldData: {},
  context: {},
  dataPrefix: '',
  defaultData: undefined,
  noRowBorders: true,
}

export default EditableTable
