export const EMPLOYER_VENUE_AREA_DELETE_BEGIN = 'syft2acp/EMPLOYER_VENUE_AREA_DELETE_BEGIN' as const
export const EMPLOYER_VENUE_AREA_DELETE_FAILED = 'syft2acp/EMPLOYER_VENUE_AREA_DELETE_FAILED' as const
export const EMPLOYER_VENUE_AREA_DELETE_SUCCEEDED = 'syft2acp/EMPLOYER_VENUE_AREA_DELETE_SUCCEEDED' as const

export const EMPLOYER_VENUE_AREA_SAVE_BEGIN = 'syft2acp/EMPLOYER_VENUE_AREA_SAVE_BEGIN' as const
export const EMPLOYER_VENUE_AREA_SAVE_FAILED = 'syft2acp/EMPLOYER_VENUE_AREA_SAVE_FAILED' as const
export const EMPLOYER_VENUE_AREA_SAVE_SUCCEEDED = 'syft2acp/EMPLOYER_VENUE_AREA_SAVE_SUCCEEDED' as const

export const EMPLOYER_VENUE_AREAS_FETCH_BEGIN = 'syftacp/EMPLOYER_VENUE_AREAS_FETCH_BEGIN' as const
export const EMPLOYER_VENUE_AREAS_FETCH_FAILED = 'syftacp/EMPLOYER_VENUE_AREAS_FETCH_FAILED' as const
export const EMPLOYER_VENUE_AREAS_FETCH_SUCCEEDED = 'syftacp/EMPLOYER_VENUE_AREAS_FETCH_SUCCEEDED' as const

export const EMPLOYER_VENUE_DELETE_BEGIN = 'syftacp/EMPLOYER_VENUE_DELETE_BEGIN' as const
export const EMPLOYER_VENUE_DELETE_FAILED = 'syftacp/EMPLOYER_VENUE_DELETE_FAILED' as const
export const EMPLOYER_VENUE_DELETE_SUCCEEDED = 'syftacp/EMPLOYER_VENUE_DELETE_SUCCEEDED' as const

export const EMPLOYER_VENUE_FETCH_BEGIN = 'syftacp/EMPLOYER_VENUE_FETCH_BEGIN' as const
export const EMPLOYER_VENUE_FETCH_FAILED = 'syftacp/EMPLOYER_VENUE_FETCH_FAILED' as const
export const EMPLOYER_VENUE_FETCH_SUCCEEDED = 'syftacp/EMPLOYER_VENUE_FETCH_SUCCEEDED' as const

export const EMPLOYER_VENUE_SAVE_BEGIN = 'syftacp/EMPLOYER_VENUE_SAVE_BEGIN' as const
export const EMPLOYER_VENUE_SAVE_FAILED = 'syftacp/EMPLOYER_VENUE_SAVE_FAILED' as const
export const EMPLOYER_VENUE_SAVE_SUCCEEDED = 'syftacp/EMPLOYER_VENUE_SAVE_SUCCEEDED' as const

export const EMPLOYER_VENUE_NAMES_FETCH_BEGIN = 'syftacp/EMPLOYER_VENUE_NAMES_FETCH_BEGIN' as const
export const EMPLOYER_VENUE_NAMES_FETCH_FAILED = 'syftacp/EMPLOYER_VENUE_NAMES_FETCH_FAILED' as const
export const EMPLOYER_VENUE_NAMES_FETCH_SUCCEEDED = 'syftacp/EMPLOYER_VENUE_NAMES_FETCH_SUCCEEDED' as const

export const EMPLOYER_VENUES_FETCH_BEGIN = 'syftacp/EMPLOYER_VENUES_FETCH_BEGIN' as const
export const EMPLOYER_VENUES_FETCH_FAILED = 'syftacp/EMPLOYER_VENUES_FETCH_FAILED' as const
export const EMPLOYER_VENUES_FETCH_SUCCEEDED = 'syftacp/EMPLOYER_VENUES_FETCH_SUCCEEDED' as const

export const EMPLOYER_VENUES_FILTERED_FETCH_BEGIN = 'syftacp/EMPLOYER_VENUES_FILTERED_FETCH_BEGIN' as const
export const EMPLOYER_VENUES_FILTERED_FETCH_FAILED = 'syftacp/EMPLOYER_VENUES_FILTERED_FETCH_FAILED' as const
export const EMPLOYER_VENUES_FILTERED_FETCH_SUCCEEDED =
  'syftacp/EMPLOYER_VENUES_FILTERED_FETCH_SUCCEEDED' as const
