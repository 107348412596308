import React, { useState } from 'react'
import { getTokenOpenLogin } from 'syft-acp-core/entities/EmployerDetail/helpers'
import { currentIntl } from 'syft-acp-util/intl'
import { getSafeCurrencySymbol } from 'syft-acp-util/formatting'
import { getSkillTitles, getConflictDates } from './helpers'
import { Box } from '@indeed/ifl-components'
import S from './MatchingExplainability.module.scss'
import { SchemaProps, CommonDescriptionProps } from './types'

const CommonDescription = ({
  firstPart,
  linkPart,
  link,
  secondPart,
  result,
  onClick,
  additionalLinks,
}: CommonDescriptionProps) => {
  const [showAllLinks, setShowAllLinks] = useState(false)
  const handleOnClick = () => {
    window.open(link)
  }
  const handleAdditionalLinkOnClick = (additionalLink: string) => () => {
    window.open(additionalLink)
  }
  const handleDotsButton = () => {
    setShowAllLinks(true)
  }
  const links = showAllLinks || !additionalLinks ? additionalLinks : additionalLinks?.slice(0, 10)
  return (
    <Box as="div">
      {firstPart}{' '}
      <Box as="span" className={S['common-description__link']} onClick={onClick ? onClick : handleOnClick}>
        {linkPart}
      </Box>{' '}
      {secondPart} {result}
      {additionalLinks &&
        links &&
        links?.map((data: { link: string; label: string }, index: number) => {
          return (
            <>
              <Box
                as="span"
                className={S['common-description__link']}
                onClick={handleAdditionalLinkOnClick(data?.link)}
              >
                {data?.label}
              </Box>
              {index < links.length - 1 ? (
                ', '
              ) : links?.length < additionalLinks?.length ? (
                <Box as="span" className={S['common-description__link']} onClick={handleDotsButton}>
                  ... more
                </Box>
              ) : (
                ''
              )}
            </>
          )
        })}
    </Box>
  )
}

export const FILTERS_SCHEMA = [
  {
    // Role
    title: 'Role Verification',
    description: (data: SchemaProps) => {
      const result = data?.roles?.[data?.subRowData?.filterData?.extra_info?.required]?.title
      return (
        <CommonDescription
          firstPart="The worker is"
          linkPart="not verified"
          secondPart="for"
          result={result}
          link={`/entity/workers/view/${data?.workerId}/interviews/list`}
        />
      )
    },
    tooltip: 'The worker will pass the filter if they are verified for the role required for the job',
    isUS: false,
    code: 'role_filter',
  },
  {
    // Skill
    title: 'Required skills',
    description: (data: SchemaProps) => {
      const result = getSkillTitles(
        data?.subRowData?.filterData?.extra_info?.required,
        data?.subRowData?.filterData?.extra_info?.actual,
        data,
      )
      return (
        <CommonDescription
          firstPart="The worker is missing the"
          linkPart="required"
          secondPart="skill(s) for the job:"
          result={result}
          link={`/entity/workers/view/${data?.workerId}/interviews/list`}
        />
      )
    },
    tooltip:
      'The worker will pass the filter if the worker has been verified for the role required by the job and has possessed all required skills (hard skills and essential soft skills) required by the job',
    isUS: false,
    code: 'skills_filter',
  },
  {
    title: 'Blocked by venue',
    description: (data: SchemaProps) => {
      // // Employer with listing is approved
      const tokenData = { id: data.employerID, approved_at: true }
      const onClick = () => {
        getTokenOpenLogin({
          actions: data.actions,
          data: tokenData,
          token: data.token,
          userData: data.userData,
          employerId: data.employerID,
          blockedWorkers: true,
        })
      }

      const result = `${data?.venues?.[data.venueID].name} ${data.venueID}`

      return (
        <CommonDescription
          firstPart="The worker is"
          linkPart="blocked"
          onClick={onClick}
          secondPart="by the following venue:"
          result={result}
          link={`/entity/employers/view/${data.employerID}/blocklist/list`}
        />
      )
    },
    tooltip: 'The worker will pass the filter if not blocked by the venue',
    isUS: false,
    code: 'blocked_at_work_location_filter',
  },
  {
    title: 'Blocked by client ',
    description: (data: SchemaProps) => {
      const employerData = `${data.employerName} (ID ${data.employerID})`
      const tokenData = { id: data.employerID, approved_at: true }
      const onClick = () => {
        getTokenOpenLogin({
          actions: data.actions,
          data: tokenData,
          token: data.token,
          userData: data.userData,
          employerId: data.employerID,
          blockedWorkers: true,
        })
      }
      return (
        <CommonDescription
          firstPart={`The worker is blocked by all venues for client ${employerData}. Please check the`}
          linkPart="blocklist"
          secondPart="on ACP and/or the"
          result={
            <>
              <Box as="span" className={S['common-description__link']} onClick={onClick}>
                blocked worker
              </Box>{' '}
              list on the client portal.
            </>
          }
          link={`/entity/employers/view/${data.employerID}/blocklist/list`}
        />
      )
    },
    tooltip: 'The worker will pass the filter if not blocked by the client',
    isUS: false,
    code: 'blocked_by_employer_filter',
  },
  {
    title: 'Availability - Worker profile setting & booked/offered status',
    description: (data: SchemaProps) => {
      const result = data?.subRowData?.filterData?.extra_info?.unavailable_timeslots?.join(', ')
      const resultConflict = data?.subRowData?.filterData?.extra_info?.booked_time_ranges
      const conflictingShiftIds = data?.subRowData?.filterData?.extra_info?.conflicting_shift_ids
      const additionalLinks = conflictingShiftIds?.map((id: number) => ({
        label: `${id}`,
        link: `/shifts/all-shifts?&shift_id=${id}`,
      }))
      const { startDate, endDate } = getConflictDates(resultConflict)
      return (
        <>
          {result && (
            <>
              <CommonDescription
                firstPart="The worker is"
                linkPart="not available"
                secondPart="for the following time slots"
                result={result}
                link={`/entity/workers/view/${data?.workerId}`}
              />
              <br />
            </>
          )}
          {!!conflictingShiftIds?.length && (
            <CommonDescription
              firstPart="The worker is booked on conflicting shifts:"
              linkPart=""
              secondPart=""
              result=""
              link={`/shifts/all-shifts?&worker_id=${data?.workerId}&start_time_gte=${startDate}&start_time_lte=${endDate}&exclude_bookings=false&disable_pagination_counters=true`}
              additionalLinks={additionalLinks}
            />
          )}
          {!conflictingShiftIds?.length && !result && 'The worker has already been offered this job.'}
        </>
      )
    },
    tooltip:
      'The worker will pass if either the job is individually bookable and at least one shift has not been offered, does not collide with existing bookings and matches the availability in the app, or the job is not individually bookable and all shifts satisfy these conditions.',
    isUS: false,
    code: 'availability_filter',
  },
  {
    title: 'Federated state ',
    tooltip:
      'The worker will pass the filter if their federated state is the exact match to the federated state of the venue it is located in',
    isUS: true,
    description: (data: SchemaProps) => {
      const required = data?.subRowData?.filterData?.extra_info?.required
      const actual = data?.subRowData?.filterData?.extra_info?.actual
      return (
        <CommonDescription
          firstPart="The worker’s set up to work in"
          linkPart=""
          secondPart={`${actual}, but the venue is located in`}
          result={required}
          link={`/entity/workers/view/${data?.workerId}`}
        />
      )
    },
    code: 'federated_state_filter',
  },
  {
    title: 'City',
    tooltip:
      'The worker will pass the filter if their city info is an exact match to the city the venue is located in',
    isUS: true,
    description: (data: SchemaProps) => {
      const link = data?.subRowData?.filterData?.extra_info?.actual
        ?.map((cityId: number) => data?.cities?.[cityId]?.name)
        .join(', ')
        .replace(/, ([^,]*)$/, ' and $1')
      const result = data?.cities?.[data?.subRowData?.filterData?.extra_info?.required]?.name
      return (
        <CommonDescription
          firstPart="The worker’s set up to work in"
          linkPart={`${link},`}
          secondPart="but the venue is located in"
          result={result}
          link={`/entity/workers/view/${data?.workerId}`}
        />
      )
    },
    code: 'city_filter',
  },
  {
    title: 'Pay rate - Worker profile setting',
    description: (data: SchemaProps) => {
      const result = `${getSafeCurrencySymbol(
        currentIntl.currencyCode,
      )}${data?.subRowData?.filterData?.extra_info?.minimum_rate.toFixed(2)}`
      return (
        <CommonDescription
          firstPart="The worker’s"
          linkPart="preferred minimum pay rate"
          secondPart="is"
          result={result}
          link={`/entity/workers/view/${data?.workerId}`}
        />
      )
    },
    tooltip:
      'The worker will pass the filter if the client’s pay rate is greater or equal to the worker’s minimum pay rate specified as a preference in-app',
    isUS: false,
    code: 'minimum_rate_filter',
  },
  {
    title: 'Distance to Venue',
    description: (data: SchemaProps) => {
      return (
        <CommonDescription
          firstPart="The worker’s"
          linkPart="preferred travel distance"
          secondPart="is < than the distance to the venue."
          result=""
          link={`/entity/workers/view/${data?.workerId}`}
        />
      )
    },
    tooltip:
      'Workers will pass this filter check if the distance to venue is ≤ the preferred travel distance. (Only applicable to non-remote jobs)',
    isUS: false,
    code: 'distance_filter',
  },
  {
    title: 'Dormant worker',
    tooltip: 'Workers will pass this filter check if they are not dormant (not active within 90 days).',
    description: (data: SchemaProps) => {
      const result = data?.subRowData?.filterData?.extra_info?.last_active_at
      return (
        <CommonDescription
          firstPart="The worker has not been"
          linkPart="active"
          secondPart="since"
          result={result}
          link={`/entity/workers/view/${data?.workerId}`}
        />
      )
    },
    isUS: false,
    code: 'activity_v2_filter',
  },
  {
    title: 'Worker working hour limit',
    description: (data: SchemaProps) => {
      return (
        <CommonDescription
          firstPart="The worker has reached their"
          linkPart=" working hour limit"
          secondPart="for the week."
          result=""
          link={`/entity/workers/view/${data?.workerId}`}
        />
      )
    },
    tooltip:
      'The worker will pass the filter if their weekly booked hours have not exceeded their weekly working hour limit',
    isUS: false,
    code: 'weekly_hours_filter',
  },
]
