import entityReducer from './generators/entityReducer'
import * as types from '../actions/action-types'
import * as entityTypes from './generators/entities'

const createNewCommissionSucceededState = (state, action) => {
  const id = action.request.options.sales_win_association_id

  return {
    ...state,
    lastMessage: '',
    lastStatus: null,
    lastBody: null,
    entityMap: {
      ...state.entityMap,
      [id]: {
        ...state.entityMap[id],
        manager_commissions: [...state.entityMap[id].manager_commissions, ...action.payload],
      },
    },
    isLoadingData: false,
  }
}

const stopCommissionSucceededState = (state, action) => {
  const { id, manager_commission_id } = action.request.options
  const now = new Date().toISOString()
  const selectedRow = state.entityMap[id].manager_commissions.find(data => data.id === manager_commission_id)

  return {
    ...state,
    lastMessage: '',
    lastStatus: null,
    lastBody: null,
    entityMap: {
      ...state.entityMap,
      [id]: {
        ...state.entityMap[id],
        manager_commissions: [
          ...state.entityMap[id].manager_commissions.filter(data => data.id !== manager_commission_id),
          {
            ...selectedRow,
            effective_time_to: now,
          },
        ],
      },
    },
    isLoadingData: false,
  }
}

export default entityReducer('commissions', {
  [entityTypes.ENTITY_SAVE_BEGIN]: types.STOP_COMMISSION_BEGIN,
  [types.STOP_COMMISSION_SUCCEEDED]: stopCommissionSucceededState,
  [entityTypes.ENTITY_SAVE_FAILED]: types.STOP_COMMISSION_FAILED,
  [entityTypes.ENTITY_SAVE_BEGIN]: types.COMMISSION_CREATE_BEGIN,
  [types.COMMISSION_CREATE_SUCCEEDED]: createNewCommissionSucceededState,
  [entityTypes.ENTITY_SAVE_FAILED]: types.COMMISSION_CREATE_FAILED,
})
