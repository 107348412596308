import { InitialState } from '../../server/utils/initialState'
import { AppConfig } from './types'
import { CountryCode } from 'syft-acp-core/lib/i18n'
import { getMaintenanceMode } from 'syft-acp-util/maintenanceMode'

export default (initialState: InitialState): AppConfig => ({
  ...initialState,
  app: 'flex-acp',
  appVersion: initialState.version.releaseVersion?.split('-')[0] ?? '',
  build: initialState.version.hash ?? '',
  ctk: initialState.ctk ?? '',
  region: initialState.apiConfig.apiRegion.replace('DEMO_', '').replace('PREPROD_', '') as CountryCode,
  isDeployEnvTestOrDev: () => initialState.stagingLevel !== 'prod',
  MAINTENANCE_MODE: getMaintenanceMode(initialState),
  isLegacyLoginFlow:
    // for now, it's the only way to turn off SSO login for Cypress tests. Page only shown for Privileged IPs users
    ('Cypress' in window && !!window.Cypress) ||
    initialState.reactAppOverrides.REACT_APP_DISABLE_SSO === 'true',
})
