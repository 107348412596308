// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React, { useEffect, useMemo, useState } from 'react'
import { flowRight } from 'lodash-es'
import { bindActionCreators, Dispatch, AnyAction } from 'redux'
import { connect, ConnectedProps } from 'react-redux'
import { TrackingTrigger, useTrackingTrigger } from '@indeed/flex-tracking-context'

import NotifyBar from 'syft-acp-atoms/NotifyBar'
import { entityDetailHOC } from 'syft-acp-util/entityDetail'
import RecordSection from 'syft-acp-core/components/RecordSection'
import type { TimeDependentRate as TimeDependentRateStore } from 'syft-acp-core/store/employerRateCards/types'
import { fetchBankHolidayRegions } from 'syft-acp-core/actions/bankHolidayRegions'
import { saveTimeDependentRates, updateTimeDependentRates } from 'syft-acp-core/actions/timeDependentRates'
import RecordControls from 'syft-acp-core/components/RecordSection/RecordControls'
import RecordHeader from 'syft-acp-core/components/RecordSection/RecordHeader'
import { Button } from 'syft-acp-atoms/Button'
import { selectBankHolidayRegionOptions } from 'syft-acp-core/store/bankHolidayRegions/selectors'
import { selectTimeDependentRates } from 'syft-acp-core/store/employerRateCards/selectors'
import { GetEntityDetailHocProps } from 'types/utils'
import Acp from 'syft-acp-uikit'

import { tdrTrackingEvents as trackingEvents } from './EmployerRateCardForm.tracking'
import { createFormFields, validateForm, formatRequest } from './TimeDependentRates.helpers'

type Props = {
  title: string
  data: TimeDependentRateStore
  rateCardId: number
  hideMarginField?: boolean
  hideInvoiceField?: boolean
}

type PropsWithConnectors = Props &
  ConnectedProps<typeof storeConnector> &
  GetEntityDetailHocProps<typeof entityDetailConnector>

export const TimeDependentRates = ({
  title,
  rateCardId,
  actions,
  data,
  countryCode,
  submitError,
  hasShiftRateType,
  bankHolidayRegions,
  timeDependentRates = [],
  timeDependentRatesRaw = [],
  hideInvoiceField,
  hideMarginField,
  actionUpdate,
  sendInitialData,
}: PropsWithConnectors) => {
  const triggerEvent = useTrackingTrigger()
  const [formErrors, setFormErrors] = useState<string[]>([])
  const [showForm, setShowForm] = useState(false)

  useEffect(() => {
    actions.fetchBankHolidayRegions(countryCode)
  }, [actions, countryCode])

  useEffect(() => {
    if (submitError) {
      setShowForm(true)
    }
  }, [submitError])

  const formFields = useMemo(
    () =>
      createFormFields(bankHolidayRegions, hasShiftRateType, hideMarginField, hideInvoiceField, countryCode),
    [bankHolidayRegions, hasShiftRateType, hideMarginField, hideInvoiceField, countryCode],
  )

  const handleAdd = () => {
    setShowForm(!showForm)
    setFormErrors([])
    sendInitialData({ rate_card_id: rateCardId })
    triggerEvent(trackingEvents.ADD_TDR.CLICKED)
  }

  const handleEdit = (id: number) => {
    const timeDependentRateData = timeDependentRatesRaw.find(item => item.id === id) || {}

    setShowForm(true)
    sendInitialData(timeDependentRateData)
    triggerEvent(trackingEvents.EDIT_TDR.CLICKED, {
      time_dependent_rate_id: id,
    })
  }

  const handleSave = () => {
    const errors = validateForm(data, countryCode)
    setFormErrors(errors)

    if (!errors.length) {
      actions.saveTimeDependentRates(formatRequest(data), false, true)
      setShowForm(false)
      triggerEvent(trackingEvents.SAVE_TDR.CLICKED)
    }
  }

  const handleUpdate = () => {
    const errors = validateForm(data, countryCode)
    setFormErrors(errors)

    if (!errors.length) {
      actions.updateTimeDependentRates(formatRequest(data), false, true)
      setShowForm(false)
      triggerEvent(trackingEvents.UPDATE_TDR.CLICKED, {
        time_dependent_rate_id: data.id,
      })
    }
  }

  return (
    <>
      <RecordControls>
        <RecordHeader sub noPadding>
          {title}
        </RecordHeader>
        <div className="right">
          <Button kind={showForm ? 'secondary' : 'success'} onClick={handleAdd}>
            {showForm ? 'Cancel' : 'Add Time Dependant Rate'}
          </Button>
          {showForm ? (
            <Button
              kind="success"
              onClick={data.id ? handleUpdate : handleSave}
              style={{ marginLeft: '12px' }}
            >
              {data.id ? 'Update' : 'Save'}
            </Button>
          ) : (
            false
          )}
        </div>
      </RecordControls>

      {showForm ? (
        <>
          <NotifyBar severity="error" conditional visible={formErrors.length > 0} noMargin>
            <div className="employer-rate-card-form__title">Errors</div>
            <ul className="employer-rate-card-form__errors">
              {formErrors.map(error => (
                <li key={error}>{error}</li>
              ))}
            </ul>
          </NotifyBar>
          <RecordSection
            title={`${data.id ? 'Update' : 'Add new'} time dependent rate`}
            structure={formFields}
            data={data}
            update={actionUpdate}
          />
          <TrackingTrigger
            onLoad={{
              event: trackingEvents[data.id ? 'EDIT_TDR' : 'ADD_TDR'].VIEWED,
              payload: {
                time_dependent_rate_id: data.id,
              },
            }}
          />
        </>
      ) : (
        false
      )}

      <Acp.EntityList data={{ data: timeDependentRates }} idKeyValue="id">
        <Acp.Table>
          <Acp.Col.Text value="id" header="ID" isMinimal isNumeric colNoLink />
          <Acp.Col.Text value="label" header="Label" colNoLink />
          <Acp.Col.Text value="weekDays" header="Day dependency" />
          <Acp.Col.Text value="timeDependency" header="Time dependency" />
          <Acp.Col.Text value="holidayRegion" header="Bank holiday region" />
          <Acp.Col.Money value="default_rate" header="Default rate" />
          <Acp.Col.Money value="minimum" header="Minimum" />
          <Acp.Col.Money value="maximum" header="Maximum" />
          <Acp.Col.Text value="marginsPercent" header="Margins (%)" />
          <Acp.Col.Money value="invoiceRate" header="Invoice rate" />
          <Acp.Col.Button header="" isMinimal>
            <Acp.Button kind="success" onClick={({ rowData }: any) => handleEdit(rowData.id)}>
              Edit
            </Acp.Button>
          </Acp.Col.Button>
        </Acp.Table>
      </Acp.EntityList>
    </>
  )
}

const mapStateToProps = (state: any, { rateCardId }: Props) => ({
  countryCode: state.app.countryCode as string,
  bankHolidayRegions: selectBankHolidayRegionOptions(state),
  timeDependentRates: selectTimeDependentRates(state, rateCardId),
  timeDependentRatesRaw: state.newEmployerRateCards.entityMap[rateCardId]
    ?.time_dependent_rates as TimeDependentRateStore[],
  submitError: state.timeDependentRates.lastMessage !== '',
  hasShiftRateType: !!state.newEmployerRateCards.entityMap[rateCardId]?.shift_rate_type_id,
})

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  actions: bindActionCreators(
    {
      fetchBankHolidayRegions,
      saveTimeDependentRates,
      updateTimeDependentRates,
    },
    dispatch,
  ),
})

export const storeConnector = connect(mapStateToProps, mapDispatchToProps)
export const entityDetailConnector = entityDetailHOC('timeDependentRates')
export default flowRight(storeConnector, entityDetailConnector)(TimeDependentRates)
