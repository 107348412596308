// Constants for supported data types. Each of these types will render a different edit component.
export const TYPE_CUSTOM = Symbol('TYPE_CUSTOM')
export const TYPE_BAN_REASONS = Symbol('TYPE_BAN_REASONS')
export const TYPE_CONDUCT_REVIEW_OUTCOME_DROPDOWN = Symbol('TYPE_CONDUCT_REVIEW_OUTCOME_DROPDOWN')
export const TYPE_BAN_REVIEW_ASSIGNED_DROPDOWN = Symbol('TYPE_BAN_REVIEW_ASSIGNED_DROPDOWN')
export const TYPE_APPEAL_OUTCOME = Symbol('TYPE_APPEAL_OUTCOME')
export const TYPE_APPEAL_REASON = Symbol('TYPE_APPEAL_REASON')
export const TYPE_AGENCIES_DROPDOWN_PRIMARY = Symbol('TYPE_AGENCIES_DROPDOWN_PRIMARY')
export const TYPE_AGENCIES_DROPDOWN_OTHER = Symbol('TYPE_AGENCIES_DROPDOWN_OTHER')
export const TYPE_TIMESTAMP = Symbol('TYPE_TIMESTAMP')
export const TYPE_DATE = Symbol('TYPE_DATE')
export const TYPE_DATE_MIN_AGE = Symbol('TYPE_DATE_MIN_AGE')
export const TYPE_TERM_DATES = Symbol('TYPE_TERM_DATES')
export const TYPE_RATING = Symbol('TYPE_RATING')
export const TYPE_TEXTAREA = Symbol('TYPE_TEXTAREA')
export const TYPE_INFINITE_TEXTAREA = Symbol('TYPE_INFINITE_TEXTAREA')
export const TYPE_RATE = Symbol('TYPE_RATE')
export const TYPE_COORDS = Symbol('TYPE_COORDS')
export const TYPE_CHECKBOX = Symbol('TYPE_CHECKBOX')
export const TYPE_CHECKBOXES = Symbol('TYPE_CHECKBOXES')
export const TYPE_REASONS_ARRAY = Symbol('TYPE_REASONS_ARRAY')
export const TYPE_ARRAY = Symbol('TYPE_ARRAY')
export const TYPE_IMAGE = Symbol('TYPE_IMAGE')
export const TYPE_TXT = Symbol('TYPE_TXT')
export const TYPE_LINK = Symbol('TYPE_LINK')
export const TYPE_AVAILABILITY = Symbol('TYPE_AVAILABILITY')
export const TYPE_ENUM = Symbol('TYPE_ENUM')
export const TYPE_MULTI_ASSOCIATIVE_ENUM = Symbol('TYPE_MULTI_ASSOCIATIVE_ENUM')
export const TYPE_CURRENCY = Symbol('TYPE_CURRENCY')
export const TYPE_PERCENT = Symbol('TYPE_PERCENT')
export const TYPE_VENUE_ADDRESS = Symbol('TYPE_VENUE_ADDRESS')
export const TYPE_JOB_SKILLS = Symbol('TYPE_JOB_SKILLS')
export const TYPE_CITY_DROPDOWN = Symbol('TYPE_CITY_DROPDOWN')
export const TYPE_STATE_DROPDOWN = Symbol('TYPE_STATE_DROPDOWN')
export const TYPE_CITY_MULTIPLE_CHOICE = Symbol('TYPE_CITY_MULTIPLE_CHOICE')
export const TYPE_MANAGER_DROPDOWN = Symbol('TYPE_MANAGER_DROPDOWN')
export const TYPE_TIER_DROPDOWN = Symbol('TYPE_TIER_DROPDOWN')
export const TYPE_CLIENT_DELIVERY_TEAM_DROPDOWN = Symbol('TYPE_CLIENT_DELIVERY_TEAM_DROPDOWN')
export const TYPE_COUNTRY_DROPDOWN = Symbol('TYPE_COUNTRY_DROPDOWN')
export const TYPE_LANGUAGE_DROPDOWN = Symbol('TYPE_LANGUAGE_DROPDOWN')
export const TYPE_TIMEZONE_DROPDOWN = Symbol('TYPE_TIMEZONE_DROPDOWN')
export const TYPE_DARK_MODE_TOGGLE = Symbol('TYPE_DARK_MODE_TOGGLE')
export const TYPE_PARTICIPANT = Symbol('TYPE_PARTICIPANT')
export const TYPE_NUMBER = Symbol('TYPE_NUMBER')
export const TYPE_AREAS_TABS = Symbol('TYPE_AREAS_TABS')
export const TYPE_VENUE_TABS = Symbol('TYPE_VENUE_TABS')
export const TYPE_ROLE_TABS = Symbol('TYPE_ROLE_TABS')
export const TYPE_INVOICING_DROPDOWN = Symbol('TYPE_INVOICING_DROPDOWN')
export const TYPE_EMPLOYER = Symbol('TYPE_EMPLOYER')
export const TYPE_EMPLOYER_VENUE = Symbol('TYPE_EMPLOYER_VENUE')
export const TYPE_ROLE = Symbol('TYPE_ROLE')
export const TYPE_INDUSTRIES_LIST = Symbol('TYPE_INDUSTRIES_LIST')
export const TYPE_MANAGER_ROLES = Symbol('TYPE_MANAGER_ROLES')
export const TYPE_STRIKES_DROPDOWN = Symbol('TYPE_STRIKES_DROPDOWN')
export const TYPE_DROPDOWN = Symbol('TYPE_DROPDOWN')
export const TYPE_PERMISSIONS = Symbol('TYPE_PERMISSIONS')
export const TYPE_SLUG = Symbol('TYPE_SLUG')
export const TYPE_EXTERNAL_UUIDS = Symbol('TYPE_EXTERNAL_UUIDS')
export const TYPE_PHONE_NUMBER = Symbol('TYPE_PHONE_NUMBER')
export const TYPE_SEPARATOR = Symbol('TYPE_SEPARATOR')
export const TYPE_FEEDBACK_TEXT = Symbol('TYPE_FEEDBACK_TEXT')
export const TYPE_DELETED_RTW_DOCUMENTS_LIST = Symbol('TYPE_DELETED_RTW_DOCUMENTS_LIST')
export const TYPE_INDUSTRIES_AND_ROLES = Symbol('TYPE_INDUSTRIES_AND_ROLES')
export const TYPE_SKILL = Symbol('TYPE_SKILL')
export const TYPE_SAVE_BUTTON = Symbol('TYPE_SAVE_BUTTON')
export const TYPE_TIME = Symbol('TYPE_TIME')
export const TYPE_NUMERIC = Symbol('TYPE_NUMERIC')
