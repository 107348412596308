import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Tab } from 'react-bootstrap'
import { get } from 'lodash-es'

import FormattedDateTime, { fullLocalDateTimeFormat } from 'syft-acp-core/components/FormattedDateTime'
import { queue } from 'syft-acp-util/wait'
import { EntityTabs, EntitySubTabs } from 'syft-acp-core/components/EntityTabs'
import { fetchAdminBookings } from 'syft-acp-core/store/listings/actions'
import {
  fetchAdminShiftListing,
  fetchAdminShiftListingClear,
} from 'syft-acp-core/store/listing-shifts/actions'

import ListingJobDetail from './ListingJob/ListingJobDetail'
import ListingNoJobs from './ListingNoJobs'
import ListingNoShifts from './ListingNoShifts'
import ListingShiftTabContent from './ListingShiftTabContent'
import { findJobTagText } from './helpers'
import { navigateListing } from './navigate'

import './ListingShiftTabs.css'

class ListingShiftTabs extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    actions: PropTypes.objectOf(PropTypes.func).isRequired,
    listingID: PropTypes.number.isRequired,
    hasJobOverbookTags: PropTypes.bool.isRequired,
    isUS: PropTypes.bool.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = this.getDefaultStates(props)
  }

  // eslint-disable-next-line react/sort-comp
  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState(this.getDefaultStates(nextProps))
  }

  componentDidUpdate(nextProps, prevState) {
    this.fetchData(prevState)
    if (this.props?.jobData?.id && nextProps?.jobData?.id !== this.props?.jobData?.id) {
      const jobID = this.props?.jobData?.id
      const shiftID = get(this.props.jobData, 'shifts[0].id')
      this.setState({
        jobID,
        shiftID: nextProps.activeShiftID && nextProps.activeShiftID !== 0 ? nextProps.activeShiftID : shiftID,
      })
    }
  }

  getDefaultStates = props => {
    // Display the active job and shift passed on from the listing page, if we have either.
    // Otherwise, show the first job and shift in the list.
    const { data } = props
    if (!data || !data.jobs) {
      return {
        id: get(props, 'data.id'),
        jobID: 0,
        shiftID: 0,
      }
    }
    const activeJobID = get(props, 'activeJobID')
    const activeShiftID = get(props, 'activeShiftID')
    const jobData = props.jobData

    // Return early if we do not have the data for this job yet.
    if (!jobData?.shifts?.length) {
      return {
        id: get(props, 'data.id'),
        jobID: activeJobID,
        shiftID: activeShiftID,
      }
    }

    const shiftN = activeShiftID ? jobData.shifts.findIndex(s => s.id === activeShiftID) : 0
    return {
      id: get(props, 'data.id'),
      jobID: get(props, `jobData.id`) || activeJobID,
      shiftID: get(props, `jobData.shifts[${shiftN}].id`) || activeShiftID,
    }
  }

  fetchData = prevState => {
    const { id, jobID, shiftID } = this.state
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const { fetchAdminShiftListing, fetchAdminBookings } = this.props.actions
    if (prevState.id === id && prevState.jobID === jobID && prevState.shiftID === shiftID) {
      return
    }
    if (id && shiftID) {
      // FIXME: potential fix for a race condition. <CMS2-924>
      queue(() => fetchAdminShiftListing(id, shiftID))
      queue(() => fetchAdminBookings(shiftID, jobID))
    } else {
      this.props.actions.fetchAdminShiftListingClear()
    }
  }

  /** Updates the current URL when a new job/shift is selected. */
  updateURL = (reqShiftID, reqJobID) => {
    const { listingID } = this.props
    const oldShiftID = this.state.shiftID
    const oldJobID = this.state.jobID
    const shiftID = reqShiftID
    const jobID = reqJobID || oldJobID
    if (shiftID === oldShiftID && jobID === oldJobID) {
      return
    }
    navigateListing({ listingID, jobID, shiftID })
  }

  handleJobSelect = jobID => {
    this.setState({ jobID })
    this.updateURL(null, jobID)
  }

  handleShiftSelect = shiftID => {
    this.setState({ shiftID })
    this.updateURL(shiftID)
  }

  render() {
    const { id, shiftID } = this.state
    const {
      data: { jobs, employer, venue },
      listingID,
      jobData,
      activeJobID,
      hasJobOverbookTags,
      isUS,
    } = this.props
    const shiftKey = (length, idx) => length + 1 + idx
    const timeZone = venue?.timezone

    if (!jobs || !jobs.length) {
      return <ListingNoJobs />
    }

    return (
      <EntityTabs activeKey={jobData?.id} onSelect={this.handleJobSelect} id="job-tab">
        {jobs &&
          jobs.map(job => {
            const startTime = job.start_time
            const endTime = job.end_time
            const findDuplicatedShift = job?.shifts?.find(shift => !!shift.duplicate_shift_category)
            const jobTagText = findJobTagText({
              startTime,
              endTime,
              duplicatedShift: findDuplicatedShift,
              isUS,
            })
            return (
              <Tab
                eventKey={job.id}
                title={
                  <>
                    {get(job, 'name')}{' '}
                    {jobTagText && hasJobOverbookTags && (
                      <span className={'type_of_workers'}>{jobTagText}</span>
                    )}
                  </>
                }
                key={job.id}
              >
                {activeJobID === job?.id && (
                  <>
                    <ListingJobDetail
                      id={job.id}
                      employerID={get(employer, 'id')}
                      venueID={get(venue, 'id')}
                      shift={get(jobData, 'shifts', []).find(shift => shift.id === shiftID)}
                      roleID={get(jobData, 'role.id')}
                      employerName={employer.name}
                      listingID={listingID}
                    />
                    <EntitySubTabs
                      activeKey={shiftID}
                      className="padded-bottom"
                      onSelect={this.handleShiftSelect}
                      id="shift-tab"
                    >
                      {jobData?.shifts?.length ? (
                        jobData.shifts.map((shift, idx) => {
                          return (
                            <Tab
                              eventKey={shift.id}
                              title={
                                <FormattedDateTime
                                  value={shift.start_time}
                                  format={{ ...fullLocalDateTimeFormat, timeZone }}
                                />
                              }
                              key={shiftKey(jobs.length, idx)}
                            >
                              {jobData.id === job.id && shiftID === shift.id && (
                                <ListingShiftTabContent
                                  breakDuration={jobData.break_duration}
                                  listingID={id}
                                  shift={shift}
                                  venue={venue}
                                  job={jobData}
                                />
                              )}
                            </Tab>
                          )
                        })
                      ) : (
                        <Tab>
                          <ListingNoShifts />
                        </Tab>
                      )}
                    </EntitySubTabs>
                  </>
                )}
              </Tab>
            )
          })}
      </EntityTabs>
    )
  }
}

export default connect(
  (state, props) => {
    return {
      jobData: state?.jobs?.entityDetail?.[props.jobID] || state?.jobs?.entityDetail?.[props.activeJobID],
    }
  },
  dispatch => ({
    actions: bindActionCreators(
      { fetchAdminShiftListing, fetchAdminShiftListingClear, fetchAdminBookings },
      dispatch,
    ),
  }),
)(ListingShiftTabs)
