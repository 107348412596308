import { memoize, keyBy, sortBy } from 'lodash-es'
import { createSelector } from 'reselect'
import entityReducer from './generators/entityReducer'

import * as types from '../actions/action-types'
import * as entityTypes from './generators/entities'

// FIXME: update this so it occurs once per login.
let hasUpdatedOnce = false

// Returns true during the first invocation, and false after.
export const needsIndustriesUpdate$ = () => {
  const reply = !hasUpdatedOnce
  hasUpdatedOnce = true
  return reply
}

/**
 * Selects a role by ID from the industries state.
 */
export const selectRole$ = createSelector(
  state => state,
  store =>
    memoize(args => {
      if (!args.roleID) {
        return []
      }
      const roles = keyBy(
        Object.values(store.entityMap).reduce((acc, curr) => {
          if (curr.roles) {
            return [...acc, ...curr.roles]
          }
          return acc
        }, []),
        'id',
      )
      return roles[args.roleID]
    }),
)

export const industryRoles = industries =>
  sortBy(
    industries.reduce((flat, toFlatten) => {
      return flat.concat(
        (toFlatten.roles ?? []).map(role => ({ id: role.id, value: role.id, label: role.title })),
      )
    }, []),
    'label',
  )

export default entityReducer('industries', {
  [entityTypes.ENTITIES_FETCH_BEGIN]: types.INDUSTRIES_FETCH_BEGIN,
  [entityTypes.ENTITIES_FETCH_SUCCEEDED]: types.INDUSTRIES_FETCH_SUCCEEDED,
  [entityTypes.ENTITIES_FETCH_FAILED]: types.INDUSTRIES_FETCH_FAILED,
})
