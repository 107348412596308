import { Box, Flex } from '@indeed/ifl-components'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateBooking, updateBookingChanges } from 'syft-acp-core/actions/bookings'

import { ButtonIcon } from 'syft-acp-atoms/Button'
import { DataDatetime } from 'syft-acp-core/components/EditableTable'
import './ListingShiftsBreaksDetails.css'

const reasonTextMap = {
  voluntary: 'Voluntarily Skipped',
  involuntary: 'Involuntarily Skipped',
}

// useful to update a specific index in an array and return a new array which is useful for state updates to trigger
const updateStateArray = (arr, index, value) => arr.map((currVal, i) => (i === index ? value : currVal))

const ListingMealBreakDetails = ({ shiftBookingId, disabled, timeZone, attr }) => {
  const dispatch = useDispatch()
  const shiftBooking = useSelector(state => state?.listingShiftBookings?.entityMap[shiftBookingId])
  const [isEditingReason, setIsEditingReason] = useState(
    new Array(shiftBooking?.meal_details?.length || 0).fill(false),
  )
  const [isAddingMeal, setIsAddingMeal] = useState(
    new Array(shiftBooking?.meal_details?.length || 0).fill(false),
  )

  const updateBookingAction = val => {
    dispatch(updateBooking({ id: shiftBookingId, attr, val }))
    dispatch(updateBookingChanges({ id: shiftBookingId, attr, val }))
  }

  const handleTimeChange = (mealIndex, key) => value => {
    const mealDetailItem = shiftBooking.meal_details[mealIndex]?.meal || {}
    const newMealDetails = Object.assign([], shiftBooking.meal_details, {
      [mealIndex]: {
        meal: {
          ...mealDetailItem,
          [key]: value,
        },
      },
    })

    updateBookingAction(newMealDetails)
  }

  const handleEditSelectOnChange = mealIndex => event => {
    const value = event.target.value

    // if the user selects a violation
    if (value) {
      const mealDetailItem = shiftBooking.meal_details[mealIndex]?.meal
      const newMealDetails = Object.assign([], shiftBooking.meal_details, {
        [mealIndex]: {
          ...(mealDetailItem
            ? {
                meal: {
                  id: mealDetailItem.id,
                },
              }
            : {}),
          violation: {
            reason: value.toUpperCase(),
          },
        },
      })

      updateBookingAction(newMealDetails)
      setIsEditingReason(editArr => updateStateArray(editArr, mealIndex, false))
    } else {
      // if they select to add a new meal
      setIsEditingReason(editArr => updateStateArray(editArr, mealIndex, false))
      setIsAddingMeal(addArr => updateStateArray(addArr, mealIndex, true))
    }
  }

  const handleEdit = mealIndex => _ => {
    setIsEditingReason(editArr => updateStateArray(editArr, mealIndex, true))
    setIsAddingMeal(addArr => updateStateArray(addArr, mealIndex, false))
  }

  const handleFinishEdit = mealIndex => _ => {
    setIsEditingReason(editArr => updateStateArray(editArr, mealIndex, false))
    setIsAddingMeal(addArr => updateStateArray(addArr, mealIndex, false))
  }

  return (
    <Box sx={{ py: 1, px: 3, minWidth: '290px' }}>
      {shiftBooking.meal_details.map((mealDetail, index) => {
        return isEditingReason?.[index] ? (
          <Flex sx={{ gap: 2, flexDirection: 'row' }} key={`meal-${index}`}>
            <Flex sx={{ flex: '1 1 100%', flexDirection: 'column', gap: 2 }}>
              <Box
                key={`meal-edit-${index}`}
                sx={{
                  pb: 2,
                  borderBottom: '1px solid #CCC',
                  '&:last-of-type': { borderBottom: 'none', pb: 0 },
                }}
              >
                <select
                  className="form-control"
                  onChange={handleEditSelectOnChange(index)}
                  value={mealDetail?.violation?.reason?.toLowerCase() || 'voluntary'}
                >
                  <option value="">Add meal start/end times</option>
                  <option value="voluntary">Voluntarily Skipped</option>
                  <option value="involuntary">Involuntarily Skipped</option>
                </select>
              </Box>
            </Flex>
            <ButtonIcon
              icon="check"
              kind="primary"
              aria-label="Finish editing"
              onClick={handleFinishEdit(index)}
            />
          </Flex>
        ) : (
          <Flex sx={{ gap: 2 }} key={`meal-${index}`}>
            <Flex sx={{ flex: '1 1 100%', flexDirection: 'column', gap: 2 }}>
              <Flex
                sx={{
                  flexDirection: 'column',
                  alignItems: 'stretch',
                  gap: 1,
                  pb: 2,
                  borderBottom: '1px solid #CCC',
                  '&:last-of-type': { borderBottom: 'none', pb: 0 },
                }}
              >
                {mealDetail.meal?.start || mealDetail.meal?.end ? (
                  <>
                    <DataDatetime
                      value={mealDetail.meal?.start || shiftBooking.start_time}
                      onChange={handleTimeChange(index, 'start')}
                      disabled={disabled}
                      parameters={{ timeZone }}
                      showTimezone={false}
                      editable
                      data={{}}
                    />
                    <DataDatetime
                      value={mealDetail.meal?.end || shiftBooking.end_time}
                      onChange={handleTimeChange(index, 'end')}
                      disabled={disabled}
                      parameters={{ timeZone }}
                      showTimezone={false}
                      editable
                      data={{}}
                    />
                  </>
                ) : isAddingMeal?.[index] ? (
                  <>
                    <DataDatetime
                      value={shiftBooking.start_time}
                      onChange={handleTimeChange(index, 'start')}
                      disabled={disabled}
                      parameters={{ timeZone }}
                      showTimezone={false}
                      editable
                      data={{}}
                    />
                    <DataDatetime
                      value={shiftBooking.end_time}
                      onChange={handleTimeChange(index, 'end')}
                      disabled={disabled}
                      parameters={{ timeZone }}
                      showTimezone={false}
                      editable
                      data={{}}
                    />
                  </>
                ) : (
                  mealDetail?.violation && (
                    <Box
                      sx={{
                        backgroundColor: '#6C757D',
                        color: '#fff',
                        borderRadius: '4px',
                        padding: '5px 11px',
                        fontWeight: 'bold',
                        lineHeight: '24px',
                      }}
                    >
                      {reasonTextMap[mealDetail.violation?.reason?.toLowerCase()]}
                    </Box>
                  )
                )}
              </Flex>
            </Flex>
            <ButtonIcon
              icon="pencil"
              aria-label="Edit"
              className={`edit-meal-${index}`}
              onClick={handleEdit(index)}
            />
          </Flex>
        )
      })}
    </Box>
  )
}

export default ListingMealBreakDetails
