import PropTypes from 'prop-types'
import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { formatCurrency } from 'syft-acp-util/formatting'
import { isFunction } from 'lodash-es'
import { useIntl } from 'react-intl'
import {
  TYPE_APPEAL_OUTCOME,
  TYPE_APPEAL_REASON,
  TYPE_BAN_REASONS,
  TYPE_CONDUCT_REVIEW_OUTCOME_DROPDOWN,
  TYPE_BAN_REVIEW_ASSIGNED_DROPDOWN,
  TYPE_AGENCIES_DROPDOWN_PRIMARY,
  TYPE_AGENCIES_DROPDOWN_OTHER,
  TYPE_ARRAY,
  TYPE_AVAILABILITY,
  TYPE_CHECKBOX,
  TYPE_CHECKBOXES,
  TYPE_CITY_DROPDOWN,
  TYPE_CITY_MULTIPLE_CHOICE,
  TYPE_COORDS,
  TYPE_COUNTRY_DROPDOWN,
  TYPE_CURRENCY,
  TYPE_DARK_MODE_TOGGLE,
  TYPE_DATE,
  TYPE_DELETED_RTW_DOCUMENTS_LIST,
  TYPE_INDUSTRIES_AND_ROLES,
  TYPE_DROPDOWN,
  TYPE_EMPLOYER,
  TYPE_EMPLOYER_VENUE,
  TYPE_ENUM,
  TYPE_EXTERNAL_UUIDS,
  TYPE_FEEDBACK_TEXT,
  TYPE_IMAGE,
  TYPE_INDUSTRIES_LIST,
  TYPE_INVOICING_DROPDOWN,
  TYPE_JOB_SKILLS,
  TYPE_LANGUAGE_DROPDOWN,
  TYPE_LINK,
  TYPE_MANAGER_DROPDOWN,
  TYPE_MANAGER_ROLES,
  TYPE_NUMBER,
  TYPE_PARTICIPANT,
  TYPE_PERCENT,
  TYPE_PERMISSIONS,
  TYPE_PHONE_NUMBER,
  TYPE_RATE,
  TYPE_RATING,
  TYPE_ROLE,
  TYPE_SAVE_BUTTON,
  TYPE_SKILL,
  TYPE_SLUG,
  TYPE_STATE_DROPDOWN,
  TYPE_STRIKES_DROPDOWN,
  TYPE_AREAS_TABS,
  TYPE_VENUE_TABS,
  TYPE_ROLE_TABS,
  TYPE_TERM_DATES,
  TYPE_TEXTAREA,
  TYPE_INFINITE_TEXTAREA,
  TYPE_TIER_DROPDOWN,
  TYPE_CLIENT_DELIVERY_TEAM_DROPDOWN,
  TYPE_TIMESTAMP,
  TYPE_TIMEZONE_DROPDOWN,
  TYPE_TXT,
  TYPE_VENUE_ADDRESS,
  TYPE_MULTI_ASSOCIATIVE_ENUM,
  TYPE_CUSTOM,
  TYPE_TIME,
  TYPE_NUMERIC,
  TYPE_DATE_MIN_AGE,
  TYPE_REASONS_ARRAY,
} from './types'
import { Button } from 'syft-acp-atoms/Button'
import LoadingIndicator from 'syft-acp-util/components/LoadingIndicator'
import * as modalActions from 'syft-acp-core/store/modals/actions'
import * as workersActions from 'syft-acp-core/store/workers/actions'
import * as usersActions from 'syft-acp-core/store/users/actions'

import DataAppealOutcomeDropdown from './DataAppealOutcomeDropdown'
import DataAppealReasonDropdown from './DataAppealReasonDropdown'
import DataBanReasons from './DataBanReasons'
import DataConductReviewOutcomeDropdown from './DataConductReviewOutcomeDropdown'
import DataBanReviewAssigneeDropdown from './DataBanReviewAssigneeDropdown'
import DataAgenciesDropdown from './DataAgenciesDropdown'
import DataArray from './DataArray'
import DataPermissions from './DataPermissions'
import DataDatetime from './DataDatetime'
import DataEmployer from './DataEmployer'
import DataEmployerVenue from './DataEmployerVenue'
import DataRole from './DataRole'
import DataSkill from './DataSkill'
import DataDate from './DataDate'
import DataDateMinAge from './DataDateMinAge'
import DataTermDates from './DataTermDates'
import DataRating from './DataRating'
import DataTextarea from './DataTextarea'
import DataRate from './DataRate'
import DataCoordinates from './DataCoordinates'
import DataBoolean from './DataBoolean'
import DataBooleanCollection from './DataBooleanCollection'
import DataEnum from './DataEnum'
import DataMultiAssociativeEnum from './DataMultiAssociativeEnum'
import DataAvailability from './DataAvailability'
import DataFileUpload from './DataFileUpload'
import DataText from './DataText'
import DataIndustriesAndRoles from './DataIndustriesAndRoles'
import DataVenueAddress from './DataVenueAddress'
import DataJobSkills from './DataJobSkills'
import DataDropdown from './DataDropdown'
import DataCityDropdown from './DataCityDropdown'
import DataStateDropdown from './DataStateDropdown'
import DataCityMultipleChoice from './DataCityMultipleChoice'
import DataManagerDropdown from './DataManagerDropdown'
import DataTierDropdown from './DataTierDropdown'
import DataClientDeliveryTeamDropdown from './DataClientDeliveryTeamDropdown'
import DataCountryDropdown from './DataCountryDropdown'
import DataLanguageDropdown from './DataLanguageDropdown'
import DataTimezoneDropdown from './DataTimezoneDropdown'
import DataDarkModeToggle from './DataDarkModeToggle'
import DataParticipant from './DataParticipant'
import DataAreasTabs from './DataAreasTabs'
import DataVenuesTabs from './DataVenuesTabs'
import DataRolesTabs from './DataRolesTabs'
import DataInvoicingTypeDropdown from './DataInvoicingTypeDropdown'
import DataIndustriesList from './DataIndustriesList'
import DataManagerRoles from './DataManagerRoles'
import DataStrikesDropdown from './DataStrikesDropdown'
import DataLink from './DataLink'
import DataExternalUUIDs from './DataExternalUUIDs'
import DataPhoneNumber from './DataPhoneNumber'
import DataFeedbackText from './DataFeedbackText'
import DataDeletedRTWDocumentsList from './DataDeletedRTWDocumentsList'
import { DataTime } from './DataTime'
import { DataNumeric } from './DataNumeric'
import DataReasonsArray from './DataReasonsArray'

import { processEnabledTriggers } from './EditableTable.helpers'

export const storeConnector = connect(
  ({ workers: { isSavingData, isLoadingData } }) => ({
    isSavingData,
    isLoadingData,
  }),
  dispatch => ({
    actions: { ...bindActionCreators({ ...modalActions, ...workersActions, ...usersActions }, dispatch) },
  }),
)

/**
 * A wrapper for the below FieldComponent, which may add an explanation of the item if provided.
 */
export const FieldComponentWrapper = ({ expl, children }) => {
  return (
    <div className="component-wrapper">
      <div className="component">{children}</div>
      {expl && <div className="expl">{expl}</div>}
    </div>
  )
}

FieldComponentWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  expl: PropTypes.string,
}

FieldComponentWrapper.defaultProps = {
  expl: null,
}
/**
 * Returns an editable form component for a given item in a structure.
 * Field of type TYPE_TXT is assumed by default.
 *
 * TODO Most fields are missing one of their non/editable counterparts
 * TODO Consider accepting generic value types instead of field types
 * (checkbox ~> bool) and then deduplicating type definitions in the form structure
 * and component prop types (maybe extract to schema definition).
 *
 * @param {String} type Data type (specified in structure)
 * @param {String} value Value of the field (specified in data)
 * @param {String} header Human-readable name of this field
 * @param {String} val Transformation function, if desired
 * @param {Boolean|Function} editable Whether this node is editable
 * @param {Boolean|String|Number} defaultValue The default value to highlight
 * @param {Function} getData Function for getting the data to pass to the component
 * @param {Function} callback Update handler for the node
 * @param {Function} callbackField Update handler for a specific field node
 * @param {Boolean} isSaved Whether this record has been saved once yet
 * @param {Object} parameters Additional options passed on to the component
 * @returns {Node} JSX node with a form component
 */
const FieldComponent = ({
  actions,
  isSavingData,
  isLoadingData,
  type,
  value,
  header,
  val,
  name,
  options,
  optionsCalc,
  editable,
  defaultValue,
  getData,
  callback,
  callbackField,
  disableOn,
  label,
  prefix,
  triggers,
  isSaved,
  context,
  data,
  parameters,
  showZero,
  suffix,
  testId,
  allowEmpty,
}) => {
  const intl = useIntl()
  const editableValue = isFunction(editable) ? editable(data) : editable

  const isEnabled = processEnabledTriggers(triggers, data)
  let componentValue = getData
    ? getData(data, {
        header: header,
        onChange: callback,
        editable: editableValue,
        disabled: !isEnabled,
      })
    : value
  // Check for triggers that change the value.
  // eslint-disable-next-line no-unused-expressions
  triggers &&
    triggers.forEach(t => {
      if (t.type === 'set' && t.toTrue != null && t.toFalse != null) {
        componentValue = data[t.on] === t.compare ? t.toTrue : t.toFalse
      }
    })

  switch (type) {
    case TYPE_CUSTOM:
      return <div>{componentValue}</div>
    case TYPE_APPEAL_OUTCOME:
      return (
        <DataAppealOutcomeDropdown
          value={componentValue}
          onChange={callback}
          editable={editableValue}
          disabled={!isEnabled}
          data={data}
        />
      )
    case TYPE_APPEAL_REASON:
      return (
        <DataAppealReasonDropdown
          value={componentValue}
          onChange={callback}
          editable={editableValue}
          disabled={!isEnabled}
          data={data}
        />
      )
    case TYPE_BAN_REASONS:
      return <DataBanReasons data={data} />
    case TYPE_CONDUCT_REVIEW_OUTCOME_DROPDOWN:
      return (
        <DataConductReviewOutcomeDropdown
          onChange={callback}
          editable={editableValue}
          disabled={!isEnabled}
          data={data}
        />
      )
    case TYPE_BAN_REVIEW_ASSIGNED_DROPDOWN:
      return (
        <DataBanReviewAssigneeDropdown
          onChange={callback}
          editable={editableValue}
          disabled={!isEnabled}
          data={data}
        />
      )
    case TYPE_AGENCIES_DROPDOWN_PRIMARY:
      return (
        <DataAgenciesDropdown
          type="primary"
          value={componentValue}
          header={header}
          onChange={callback}
          editable={editableValue}
          disabled={!isEnabled}
          data={data}
        />
      )
    case TYPE_AGENCIES_DROPDOWN_OTHER:
      return (
        <DataAgenciesDropdown
          type="other"
          value={componentValue}
          header={header}
          onChange={callback}
          editable={editableValue}
          disabled={!isEnabled}
          data={data}
        />
      )
    case TYPE_ARRAY:
      return (
        <DataArray
          value={componentValue}
          prefix={prefix}
          header={header}
          parameters={parameters}
          onChange={callback}
          enabled={isEnabled}
          editable={editableValue}
        />
      )
    case TYPE_PERMISSIONS:
      return (
        <DataPermissions
          value={componentValue}
          prefix={prefix}
          header={header}
          context={context}
          parameters={parameters}
          onChange={callback}
          editable={editableValue}
        />
      )
    case TYPE_TIMESTAMP:
      return (
        <DataDatetime
          value={componentValue}
          editable={editableValue}
          onChange={callback}
          enabled={isEnabled}
          parameters={parameters}
          data={data}
        />
      )
    case TYPE_INDUSTRIES_AND_ROLES:
      return (
        <DataIndustriesAndRoles
          value={componentValue}
          prefix={prefix}
          header={header}
          context={context}
          parameters={parameters}
          onChange={callback}
          editable={editable}
        />
      )
    case TYPE_EMPLOYER:
      return (
        <DataEmployer
          value={componentValue}
          editable={editableValue}
          isSaved={isSaved}
          parameters={parameters}
          onChange={callback}
          enabled={isEnabled}
        />
      )
    case TYPE_EMPLOYER_VENUE:
      return (
        <DataEmployerVenue
          value={componentValue}
          editable={editableValue}
          isSaved={isSaved}
          parameters={parameters}
          onChange={callback}
          enabled={isEnabled}
        />
      )
    case TYPE_ROLE:
      return (
        <DataRole
          value={componentValue}
          editable={editableValue}
          isSaved={isSaved}
          parameters={parameters}
          onChange={callback}
          enabled={isEnabled}
          label={label}
        />
      )
    case TYPE_SKILL:
      return (
        <DataSkill
          value={componentValue}
          editable={editableValue}
          isSaved={isSaved}
          parameters={parameters}
          onChange={callback}
          enabled={isEnabled}
        />
      )
    case TYPE_DATE:
      return (
        <DataDate
          value={componentValue}
          defaultValue={defaultValue}
          editable={editableValue}
          parameters={parameters}
          onChange={callback}
          disabled={!isEnabled}
        />
      )
    case TYPE_DATE_MIN_AGE:
      return (
        <DataDateMinAge
          value={componentValue}
          defaultValue={defaultValue}
          editable={editableValue}
          parameters={parameters}
          onChange={callback}
          disabled={!isEnabled}
        />
      )
    case TYPE_TERM_DATES:
      return (
        <DataTermDates
          value={componentValue}
          editable={editableValue}
          parameters={parameters}
          onChange={callback}
          disabled={!isEnabled}
        />
      )
    case TYPE_RATING:
      return (
        <DataRating
          amount={componentValue != null ? Number(componentValue) : componentValue}
          showAmount
          editable={editableValue}
          enabled={isEnabled}
        />
      )
    case TYPE_TEXTAREA:
      return (
        <DataTextarea
          value={componentValue ? String(componentValue) : ''}
          header={header}
          onChange={callback}
          enabled={isEnabled}
          editable={editable}
        />
      )
    case TYPE_INFINITE_TEXTAREA:
      return (
        <DataTextarea
          value={componentValue ? String(componentValue) : ''}
          header={header}
          onChange={callback}
          enabled={isEnabled}
          infinite
        />
      )
    case TYPE_RATE:
      return (
        <DataRate
          onChange={callback}
          showZero={showZero}
          allData={data}
          disableOn={disableOn}
          suffix={suffix}
          value={componentValue || (allowEmpty ? null : { amount: 0, currency: intl.currencyCode })}
          editable={editableValue}
          enabled={isEnabled}
          allowEmpty={allowEmpty}
          parameters={parameters}
        />
      )
    case TYPE_COORDS:
      return (
        <DataCoordinates
          value={componentValue || { latitude: 0, longitude: 0 }}
          onChange={callback}
          enabled={isEnabled}
        />
      )
    case TYPE_CHECKBOX:
      return (
        <DataBoolean
          onChange={callback}
          editable={!!editableValue && isEnabled}
          checked={componentValue}
          label={label}
          enabled={isEnabled}
          testId={testId}
          parameters={parameters}
        />
      )
    case TYPE_CHECKBOXES:
      return (
        <DataBooleanCollection
          onChange={callback}
          editable={!!editableValue}
          selected={componentValue}
          enabled={isEnabled}
          options={options.length ? options : optionsCalc(data)}
          display={parameters.display}
        />
      )
    case TYPE_REASONS_ARRAY:
      return (
        <DataReasonsArray
          value={componentValue}
          prefix={prefix}
          header={header}
          parameters={parameters}
          onChange={callback}
          enabled={isEnabled}
          editable={editableValue}
        />
      )
    case TYPE_ENUM:
      return (
        <DataEnum
          {...{
            componentValue,
            defaultValue,
            val,
            data: val ? data : null,
            editable: editable && isEnabled,
            options,
            enabled: isEnabled,
            onChange: callback,
            parameters,
          }}
        />
      )
    case TYPE_MULTI_ASSOCIATIVE_ENUM:
      return (
        <DataMultiAssociativeEnum
          {...{
            value: componentValue,
            defaultValue,
            val,
            data: val ? data : null,
            editable: editable && isEnabled,
            options,
            enabled: isEnabled,
            onChange: callback,
            parameters,
          }}
        />
      )
    case TYPE_AVAILABILITY:
      return (
        <DataAvailability
          data={componentValue || []}
          onChange={callback}
          editable={editableValue}
          enabled={isEnabled}
        />
      )
    case TYPE_IMAGE:
      return (
        <DataFileUpload value={componentValue} otherData={data} onChange={callback} enabled={isEnabled} />
      )
    case TYPE_CURRENCY:
      return <DataText value={formatCurrency(componentValue)} enabled={isEnabled} />
    case TYPE_PERCENT:
      return (
        <DataText
          value={componentValue != null ? `${String(componentValue)}${editable ? '' : '%'}` : ''}
          prefix="%"
          header={header}
          enabled={isEnabled}
          onChange={callback}
          editable={editableValue}
          parameters={parameters}
        />
      )
    case TYPE_NUMERIC:
      return (
        <DataNumeric
          value={componentValue != null ? String(componentValue) : ''}
          prefix={prefix}
          suffix={suffix}
          header={header}
          enabled={isEnabled}
          onChange={callback}
          editable={editableValue}
          ariaLabel={parameters.ariaLabel}
        />
      )
    case TYPE_VENUE_ADDRESS:
      return <DataVenueAddress address={componentValue || {}} enabled={isEnabled} />
    case TYPE_JOB_SKILLS:
      return (
        <DataJobSkills
          skills={componentValue}
          otherData={data}
          editable={editableValue}
          onChange={callback}
          enabled={isEnabled}
        />
      )
    case TYPE_DROPDOWN:
      return (
        <DataDropdown
          value={componentValue}
          options={options}
          header={header}
          onChange={callback}
          editable={editableValue}
          disabled={!isEnabled}
          parameters={parameters}
        />
      )
    case TYPE_CITY_DROPDOWN:
      return (
        <DataCityDropdown
          value={componentValue}
          header={header}
          name={name}
          onChange={callback}
          editable={editableValue}
          enabled={isEnabled}
        />
      )
    case TYPE_STATE_DROPDOWN:
      return (
        <DataStateDropdown
          value={componentValue}
          header={header}
          name={name}
          onChange={callback}
          editable={editableValue}
          enabled={isEnabled}
        />
      )
    case TYPE_CITY_MULTIPLE_CHOICE:
      return (
        <DataCityMultipleChoice
          value={componentValue}
          header={header}
          onChange={callback}
          editable={editableValue}
          enabled={isEnabled}
        />
      )
    case TYPE_MANAGER_DROPDOWN:
      return (
        <DataManagerDropdown
          value={componentValue}
          header={header}
          name={header}
          onChange={callback}
          editable={editableValue}
          parameters={parameters}
          enabled={isEnabled}
        />
      )
    case TYPE_TIER_DROPDOWN:
      return (
        <DataTierDropdown
          value={componentValue}
          header={header}
          onChange={callback}
          editable={editableValue}
          enabled={isEnabled}
        />
      )
    case TYPE_CLIENT_DELIVERY_TEAM_DROPDOWN:
      return (
        <DataClientDeliveryTeamDropdown
          name={header}
          value={componentValue}
          header={header}
          onChange={callback}
          editable={editableValue}
          enabled={isEnabled}
        />
      )
    case TYPE_COUNTRY_DROPDOWN:
      return (
        <DataCountryDropdown
          value={componentValue}
          header={header}
          onChange={callback}
          enabled={isEnabled}
          editable={editableValue}
        />
      )
    case TYPE_LANGUAGE_DROPDOWN:
      return (
        <DataLanguageDropdown
          value={componentValue}
          header={header}
          onChange={callback}
          enabled={isEnabled}
          editable={editableValue}
        />
      )
    case TYPE_TIMEZONE_DROPDOWN:
      return (
        <DataTimezoneDropdown
          value={componentValue}
          header={header}
          onChange={callback}
          enabled={isEnabled}
          editable={editableValue}
        />
      )
    case TYPE_DARK_MODE_TOGGLE:
      return (
        <DataDarkModeToggle
          onChange={callback}
          editable={!!editableValue && isEnabled}
          checked={componentValue}
          enabled={isEnabled}
          label={label}
        />
      )
    case TYPE_PARTICIPANT:
      return <DataParticipant enabled={isEnabled} participant={componentValue} />
    case TYPE_AREAS_TABS:
      return <DataAreasTabs hideContent data={data} enabled={isEnabled} value={componentValue} />
    case TYPE_VENUE_TABS:
      return <DataVenuesTabs data={data} enabled={isEnabled} value={componentValue} />
    case TYPE_ROLE_TABS:
      return (
        <DataRolesTabs callbackField={callbackField} data={data} enabled={isEnabled} value={componentValue} />
      )
    case TYPE_NUMBER:
      return (
        <DataText
          value={componentValue != null ? String(componentValue) : ''}
          header={header}
          enabled={isEnabled}
          onChange={callback}
          editable={editableValue}
          type="number"
        />
      )
    case TYPE_INVOICING_DROPDOWN:
      return (
        <DataInvoicingTypeDropdown
          value={componentValue.value}
          options={componentValue.options}
          header={header}
          onChange={callback}
          enabled={isEnabled}
          editable={editableValue}
        />
      )
    case TYPE_INDUSTRIES_LIST:
      return (
        <DataIndustriesList
          value={componentValue}
          prefix={prefix}
          header={header}
          parameters={parameters}
          onChange={callback}
          editable={editableValue}
          enabled={isEnabled}
        />
      )
    case TYPE_MANAGER_ROLES:
      return (
        <DataManagerRoles
          value={componentValue}
          header={header}
          onChange={callback}
          enabled={isEnabled}
          editable={editableValue}
        />
      )
    case TYPE_STRIKES_DROPDOWN:
      return (
        <DataStrikesDropdown
          value={componentValue}
          header={header}
          onChange={callback}
          enabled={isEnabled}
          editable={editableValue}
        />
      )
    case TYPE_LINK:
      return (
        <DataLink
          value={componentValue != null ? String(componentValue) : ''}
          prefix={prefix}
          header={header}
          data={data}
          parameters={parameters}
          onChange={callback}
          editable={editableValue}
        />
      )
    case TYPE_SLUG:
      return (
        <DataText
          value={componentValue != null ? String(componentValue) : defaultValue || ''}
          slug
          prefix={prefix}
          header={header}
          onChange={callback}
          enabled={isEnabled}
          editable={editableValue}
        />
      )
    case TYPE_EXTERNAL_UUIDS:
      return (
        <DataExternalUUIDs
          value={componentValue}
          prefix={prefix}
          header={header}
          onChange={callback}
          enabled={isEnabled}
          editable={editableValue}
        />
      )
    case TYPE_PHONE_NUMBER:
      return (
        <DataPhoneNumber
          value={componentValue != null ? String(componentValue) : defaultValue || ''}
          header={header}
          onChange={callback}
          enabled={isEnabled}
          editable={editableValue}
        />
      )
    case TYPE_FEEDBACK_TEXT:
      return (
        <DataFeedbackText
          value={componentValue != null ? String(componentValue) : ''}
          data={data}
          parameters={parameters}
          disabled={!isEnabled}
        />
      )
    case TYPE_DELETED_RTW_DOCUMENTS_LIST:
      return <DataDeletedRTWDocumentsList workerId={data.id} />
    case TYPE_SAVE_BUTTON:
      return (
        <div>
          <Button onClick={() => actions.saveWorker(data.id, data)} kind="success">
            Save
          </Button>
          <LoadingIndicator loadingState={isSavingData || isLoadingData} />
        </div>
      )
    case TYPE_TIME:
      return (
        <DataTime
          value={componentValue}
          disabled={!isEnabled || !editable}
          header={header}
          onChange={callback}
        />
      )
    default:
      return (
        <DataText
          value={componentValue}
          defaultValue={defaultValue || ''}
          prefix={prefix}
          header={header}
          onChange={callback}
          disabled={!isEnabled}
          editable={editableValue}
          parameters={parameters}
        />
      )
  }
}

FieldComponent.propTypes = {
  actions: PropTypes.object.isRequired,
  isSavingData: PropTypes.bool.isRequired,
  isLoadingData: PropTypes.bool.isRequired,
  type: PropTypes.symbol,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
    PropTypes.array,
    PropTypes.bool,
  ]),
  header: PropTypes.string,
  name: PropTypes.string,
  suffix: PropTypes.string,
  label: PropTypes.string,
  showZero: PropTypes.bool,
  disableOn: PropTypes.array,
  val: PropTypes.func,
  prefix: PropTypes.string,
  editable: PropTypes.bool,
  getData: PropTypes.func,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  options: PropTypes.array,
  optionsCalc: PropTypes.func,
  callback: PropTypes.func,
  isSaved: PropTypes.bool,
  data: PropTypes.object,
  context: PropTypes.object,
  triggers: PropTypes.array,
  parameters: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  testId: PropTypes.string,
  allowEmpty: PropTypes.bool,
}

FieldComponent.defaultProps = {
  type: TYPE_TXT,
  val: null,
  prefix: null,
  value: null,
  label: null,
  name: null,
  context: null,
  triggers: null,
  header: null,
  suffix: '',
  editable: false,
  disableOn: false,
  showZero: true,
  getData: null,
  options: [],
  optionsCalc: () => {},
  callback: () => {},
  isSaved: false,
  data: {},
  parameters: {},
  defaultValue: null,
  testId: null,
}

export default storeConnector(FieldComponent)
