import * as React from 'react'

import { ButtonIcon } from 'syft-acp-atoms/Button'

import { FileUploadButtonProps as Props } from './FileUploadButton.types'

const FileUploadButton = ({ text = 'Upload file', ...props }: Props) => (
  // @ts-expect-error
  (<ButtonIcon icon="cloud-upload" left iconSize={16} kind="regular" right={false} {...props}>
    {text}
  </ButtonIcon>)
)

export default FileUploadButton
