import { call, put } from 'redux-saga/effects'
import { failSaveBookings, successSaveBookings, blockWorkerForClient } from 'syft-acp-core/actions/bookings'
import { bulkEditAdminTimesheets } from 'syft-acp-core/api/resources/admin/timesheets'
import { createBooking, deleteBooking, replaceBooking } from 'syft-acp-core/api/resources/bookings'
import { draftKey } from 'syft-acp-core/reducers/states/listingShiftBookingsStates'
import { fetchAdminBookings } from 'syft-acp-core/store/listings/actions'
import { removeAdminShiftSpot } from 'syft-acp-core/store/shifts/api'
import { buildSaveBookingParams } from './params/saveBookingsParams'

const getShiftID = entityMap => {
  const key = Object.keys(entityMap)[0]
  return entityMap[key].shiftID
}

export const isDraft = id => !!String(id).match(draftKey)

export function* saveBookingsCall({ payload }) {
  const { entityMap, entityMapChanges, jobID, jobShiftIDs, caMealBreakFlag } = payload
  const {
    bulkEditParams,
    createBookingParams,
    replaceBookingParams,
    cancelBookingParams,
    removeBookingParams,
  } = buildSaveBookingParams(entityMap, entityMapChanges, jobShiftIDs, caMealBreakFlag)

  try {
    if (bulkEditParams.length) {
      yield call(bulkEditAdminTimesheets, { payload: { entries: bulkEditParams } })
    }

    if (createBookingParams.length) {
      for (let i = 0, len = createBookingParams.length; i < len; i += 1) {
        const params = createBookingParams[i]
        yield call(createBooking, { payload: { ...params, job_id: jobID } })
      }
    }

    if (replaceBookingParams.length) {
      for (let i = 0, len = replaceBookingParams.length; i < len; i += 1) {
        const params = replaceBookingParams[i]
        yield call(replaceBooking, { payload: params })
      }
    }

    if (cancelBookingParams.length) {
      for (let i = 0, len = cancelBookingParams.length; i < len; i += 1) {
        const params = cancelBookingParams[i]
        yield call(deleteBooking, { payload: params })
      }
    }

    if (removeBookingParams.length) {
      for (let i = 0, len = removeBookingParams.length; i < len; i += 1) {
        const params = removeBookingParams[i]
        yield call(removeAdminShiftSpot, { payload: params })
      }
    }

    yield put(fetchAdminBookings(getShiftID(entityMap), jobID))
    yield put(successSaveBookings())
  } catch (e) {
    yield put(failSaveBookings(e))
  }
}

export function* saveEndAssignmentWorkerCall({ payload, blockWorker, employerId, venueId }) {
  try {
    yield call(deleteBooking, { payload: payload })

    if (blockWorker === 'venue') {
      // call block venue here
      yield put(blockWorkerForClient(payload.worker_id, employerId, venueId))
    }
    if (blockWorker === 'employer') {
      // call employer here
      yield put(blockWorkerForClient(payload.worker_id, employerId))
    }
  } catch (e) {
    yield put(failSaveBookings(e))
  }
  yield put(fetchAdminBookings(payload.shift_id, payload.job_id))
  yield put(successSaveBookings(payload))
}
